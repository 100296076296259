const getDiffFromObjects = (original, updated) => {
  let editedFields = {};

  Object.entries(original).forEach(([key, value]) => {
    if (
      typeof value === 'object' &&
      value !== null &&
      value !== undefined &&
      !Array.isArray(value)
    ) {
      editedFields = {
        ...editedFields,
        ...getDiffFromObjects(value, updated[key]),
      };
    } else if (value !== updated[key]) {
      editedFields[key] = true;
    }
  });

  return editedFields;
};

export default getDiffFromObjects;
