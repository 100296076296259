import './customerDetailsForm.scss';

import { h, Fragment } from 'preact';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Checkbox } from '@yoti/ui-kit-components-react/checkbox';
import { Button, BUTTON_VARIATIONS } from '@yoti/ui-kit-components-react/button';
import { SelectField } from '@yoti/ui-kit-components-react/select-field';
import classnames from 'classnames';
import { i18n } from '@lingui/core';
import {
  required,
  isEmail,
  isPhoneNumber,
  hasNoSpecialChars,
  createFieldValidator,
  isDate,
} from '../../services/validators';
import { TextField } from '../textField/textField';
import { AddressSection } from './addressSection';
import { DocumentSection } from './documentSection';
import { OtherDetailsSection } from './otherDetailsSection';
import GENDERS from '../../constant/genders';
import { TEST_SCHEME_VALUES } from '../../constant/testingSchemes';
import { getVisibleFields } from '../../services/additionalFields';
import { CountryField } from '../countryField/countryField';

export const CustomerDetailsForm = ({
  initialValues,
  onSubmit,
  submitText,
  onCancel,
  className,
  additionalFields,
  testingSchemes,
  isYoti,
  canAutoComplete,
  forceEmail,
  canVerifyId,
}) => {
  const displayFields = getVisibleFields(additionalFields, forceEmail ? ['email'] : []);
  const genders = GENDERS.map(({ label, value }) => ({ label: i18n._(label), value }));

  const emailField = displayFields.find(({ name }) => name === 'email');
  const fullNameField = displayFields.find(({ name }) => name === 'full_name');
  const givenNamesField = displayFields.find(({ name }) => name === 'given_names');
  const familyNameField = displayFields.find(({ name }) => name === 'family_name');
  const dobField = displayFields.find(({ name }) => name === 'date_of_birth');
  const phoneField = displayFields.find(({ name }) => name === 'phone');
  const genderField = displayFields.find(({ name }) => name === 'gender');
  const nationalityField = displayFields.find(({ name }) => name === 'nationality');
  const addressFields = displayFields.find(({ name }) => name === 'home_address');
  const documentField = displayFields.find(({ name }) => name === 'document');

  const documentType = documentField && documentField.sources && documentField.sources[0];
  const newInitialValues = {
    ...(testingSchemes?.length && { testingScheme: TEST_SCHEME_VALUES.STANDARD }),
    ...initialValues,
    ...(documentType && { documentType }),
  };
  const formClassName = classnames('customer-details-form', className);

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={newInitialValues}
        render={({ handleSubmit, submitting }) => (
          <form
            className={formClassName}
            onSubmit={handleSubmit}
            autoComplete={canAutoComplete ? 'on' : 'off'}
            data-qa="customer-details-form"
          >
            <div className="customer-details-form__fields" data-qa="customer-details-form-fields">
              {/* another attempt of forcing auto complete to be off */}
              {!canAutoComplete && (
                <input type="email" name="email" className="customer-details-form__hidden-field" />
              )}

              {!isYoti && (
                <Fragment>
                  {fullNameField && (
                    <div className="customer-details-form__field">
                      <TextField
                        name="fullName"
                        validators={[
                          ...(fullNameField.optional ? [] : [required]),
                          hasNoSpecialChars,
                        ]}
                        label={t`Full name`}
                        autoComplete={canAutoComplete ? 'name' : 'chrome-fix-with-unique-name'}
                        autoFocus
                        data-qa="customer-details-form-full-name"
                      />
                    </div>
                  )}
                  {givenNamesField && (
                    <div className="customer-details-form__field">
                      <TextField
                        name="givenNames"
                        validators={[
                          ...(givenNamesField.optional ? [] : [required]),
                          hasNoSpecialChars,
                        ]}
                        label={t`Given name(s)`}
                        autoComplete={
                          canAutoComplete ? 'given-name' : 'chrome-fix-with-unique-name'
                        }
                        data-qa="customer-details-form-given-names"
                      />
                    </div>
                  )}
                  {familyNameField && (
                    <div className="customer-details-form__field">
                      <TextField
                        name="familyName"
                        validators={[
                          ...(familyNameField.optional ? [] : [required]),
                          hasNoSpecialChars,
                        ]}
                        label={t`Family name`}
                        autoComplete={
                          canAutoComplete ? 'family-name' : 'chrome-fix-with-unique-name'
                        }
                        data-qa="customer-details-form-family-name"
                      />
                    </div>
                  )}

                  {canVerifyId && (fullNameField || familyNameField || givenNamesField) && (
                    <Field name="hasVerifiedID">
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          label={
                            <div>
                              <div>{t`Verified name with Photo ID`}</div>
                              <div className="customer-details-form__docs">{t`Passport, Driving licence or National ID`}</div>
                            </div>
                          }
                          data-qa="customer-details-form-verify"
                          fullWidth
                        />
                      )}
                    </Field>
                  )}

                  {emailField && (
                    <div className="customer-details-form__field">
                      <TextField
                        name="email"
                        validators={[...(emailField.optional ? [] : [required]), isEmail]}
                        type="email"
                        label={t`Email`}
                        autoComplete={canAutoComplete ? 'email' : 'chrome-fix-with-unique-name'}
                        data-qa="customer-details-form-email"
                      />
                    </div>
                  )}

                  {phoneField && (
                    <div className="customer-details-form__field">
                      <TextField
                        name="phone"
                        validators={[...(phoneField.optional ? [] : [required]), isPhoneNumber]}
                        type="phone"
                        label={t`Phone`}
                        autoComplete={canAutoComplete ? 'tel' : 'chrome-fix-with-unique-name'}
                        data-qa="customer-details-form-phone"
                      />
                    </div>
                  )}

                  {dobField && (
                    <div className="customer-details-form__field">
                      <TextField
                        name="dateOfBirth"
                        validators={[...(dobField.optional ? [] : [required]), isDate]}
                        placeholder="yyyy-mm-dd"
                        type="date"
                        label={t`Date of birth`}
                        autoComplete={canAutoComplete ? 'bday' : 'chrome-fix-with-unique-name'}
                        data-qa="customer-details-form-dob"
                      />
                    </div>
                  )}

                  {genderField && (
                    <div className="customer-details-form__field">
                      <Field
                        name="gender"
                        validate={genderField.optional ? undefined : createFieldValidator(required)}
                      >
                        {({ input, meta }) => {
                          const error = meta.touched && meta.error;
                          return (
                            <SelectField
                              {...input}
                              selectedValue={input.value}
                              label={t`Gender`}
                              options={genders}
                              error={error ? i18n._(error) : undefined}
                              data-qa="customer-details-form-gender"
                            />
                          );
                        }}
                      </Field>
                    </div>
                  )}

                  {nationalityField && (
                    <div className="customer-details-form__field">
                      <CountryField
                        name="nationality"
                        label={t`Nationality`}
                        validators={nationalityField.optional ? [] : [required]}
                        data-qa="customer-details-form-nationality"
                      />
                    </div>
                  )}

                  {addressFields && <AddressSection canAutoComplete={canAutoComplete} />}

                  {documentType && (
                    <DocumentSection
                      documentType={documentType}
                      canAutoComplete={canAutoComplete}
                    />
                  )}
                </Fragment>
              )}

              <OtherDetailsSection
                additionalFields={additionalFields}
                testingSchemes={testingSchemes}
                canAutoComplete={canAutoComplete}
              />
            </div>
            <div>
              <div className="customer-details-form__button">
                <Button
                  type="submit"
                  disabled={submitting}
                  fullWidth
                  data-qa="customer-details-form-submit"
                >
                  {submitText || t`Continue`}
                </Button>
              </div>

              {onCancel && (
                <div className="customer-details-form__button">
                  <Button fullWidth variation={BUTTON_VARIATIONS.SECONDARY} onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </form>
        )}
      />
    </div>
  );
};

CustomerDetailsForm.defaultProps = {
  initialValues: {
    fullName: '',
    hasVerifiedID: false,
    email: '',
    phone: '',
  },
  additionalFields: [],
  testingSchemes: [],
  isYoti: false,
  forceEmail: true,
  canAutoComplete: false,
  canVerifyId: true,
  onCancel: undefined,
};

CustomerDetailsForm.propTypes = {
  initialValues: PropTypes.shape({
    fullName: PropTypes.string,
    hasVerifiedID: PropTypes.bool,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  additionalFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.name,
    })
  ),
  testingSchemes: PropTypes.arrayOf(PropTypes.string),
  isYoti: PropTypes.bool,
  forceEmail: PropTypes.bool,
  canAutoComplete: PropTypes.bool,
  canVerifyId: PropTypes.bool,
  onCancel: PropTypes.func,
};
