import { useState, useEffect } from 'preact/hooks';
import { mediaQueries } from '@yoti/ui-kit-components-react';

export const WINDOW_TYPE = {
  LARGE: 'LARGE',
  SMALL: 'SMALL',
};

export default function useWindowSize() {
  const fromMediumViewportQuery = mediaQueries.yotiFromMediumViewport.replace(/['"]+/g, '');
  const getWindowType = (matches) => (matches ? WINDOW_TYPE.LARGE : WINDOW_TYPE.SMALL);
  const mediumViewPort = window.matchMedia(fromMediumViewportQuery);

  const [windowSize, setWindowSize] = useState(getWindowType(mediumViewPort.matches));

  const updateWindowType = ({ matches }) => {
    setWindowSize(getWindowType(matches));
  };

  useEffect(() => {
    mediumViewPort.addEventListener('change', updateWindowType);
    return () => mediumViewPort.removeEventListener('change', updateWindowType);
  }, []);

  return windowSize;
}
