import { t } from '@lingui/macro';

export const ETHNICITIES = {
  EthnicityBangladeshi: { label: t`Bangladeshi`, value: 'BANGLADESHI' },
  EthnicityBlackAfrican: { label: t`Black African`, value: 'BLACK - AFRICAN' },
  EthnicityBlackCaribbean: { label: t`Black Caribbean`, value: 'BLACK - CARIBBEAN' },
  EthnicityBlackOther: { label: t`Black Other`, value: 'BLACK - OTHER' },
  EthnicityChinese: { label: t`Chinese`, value: 'CHINESE' },
  EthnicityIndian: { label: t`Indian`, value: 'INDIAN' },
  EthnicityPakistani: { label: t`Pakistani`, value: 'PAKISTANI' },
  EthnicityWhite: { label: t`White`, value: 'WHITE' },
  EthnicityWhiteAndAsian: { label: t`White and Asian`, value: 'WHITE AND ASIAN' },
  EthnicityWhiteAndBlackAfrican: {
    label: t`White and Black African`,
    value: 'WHITE AND BLACK AFRICAN',
  },
  EthnicityWhiteAndBlackCaribbean: {
    label: t`White and Black Caribbean`,
    value: 'WHITE AND BLACK CARIBBEAN',
  },
  EthnicityWhiteBritish: { label: t`White British`, value: 'WHITE BRITISH' },
  EthnicityWhiteIrish: { label: t`White Irish`, value: 'WHITE IRISH' },
  EthnicityWhiteOther: { label: t`White other`, value: 'WHITE OTHER' },
  EthnicityIscUnspecified: { label: t`ISC Unspecified`, value: 'ISC - UNSPECIFIED' },
  EthnicityAnyOtherEthnicCategory: {
    label: t`Any other ethnic category`,
    value: 'ANY OTHER ETHNIC CATEGORY',
  },
  EthnicityAnyOtherMixedGroup: { label: t`Any other mixed group`, value: 'ANY OTHER MIXED GROUP' },
  EthnicityOtherMixed: { label: t`Other / Mixed`, value: 'OTHER / MIXED' },
  EthnicityUnknown: { label: t`Unknown`, value: 'UNKNOWN' },
};
