import { i18n } from '@lingui/core';
import { TEST_OPTIONS } from '../constant/testingOptions';
import {
  TEST_SCHEME_OPTIONS,
  TEST_SCHEME_STANDARD_VALUE,
  TEST_SCHEME_VALUES,
} from '../constant/testingSchemes';

export const getRegistrationInvitesInServerFormat = (invites) => {
  return invites.map(({ type, testing_scheme: testingScheme, ...invite }) => ({
    type,
    ...(testingScheme && {
      testing_scheme: testingScheme === TEST_SCHEME_STANDARD_VALUE ? '' : testingScheme,
    }),
    contact_info: { ...invite },
  }));
};

export const getValueForStandardOption = (schemeOptions) =>
  schemeOptions.map((scheme) =>
    scheme.value ? scheme : { ...scheme, value: TEST_SCHEME_STANDARD_VALUE }
  );

export const getAvailableTestingSchemeOptions = (availableSchemes, withStandardValue) => {
  const schemes = availableSchemes?.length
    ? TEST_SCHEME_OPTIONS.filter(
        ({ value }) => availableSchemes?.includes(value) || value === TEST_SCHEME_VALUES.STANDARD
      )
    : [];
  const standardisedSchemes = withStandardValue ? getValueForStandardOption(schemes) : schemes;
  return standardisedSchemes.map(({ label, value }) => ({ label: i18n._(label), value }));
};

export const getAvailableTypeOptions = (availableTypes) =>
  TEST_OPTIONS.filter(({ value }) => availableTypes[value.toLowerCase()]);

export const checkHasMultipleSchemes = (orgSchemes, inviteType) =>
  !inviteType && !!orgSchemes?.length;
