import axios from 'axios';
import { navigate } from './utils';
import { LOGOUT, UNAUTHORISED } from '../constant/routes';
import { ServerError } from './ServerError';
import { ERROR_CODES } from '../constant/errorCodes';

function getStorageName(config) {
  return config?.url.includes('/iam') ? 'iamCsrf' : 'csrf';
}

function setCSRF(config, headers = config?.headers) {
  const token = headers && headers['x-csrf-token'];
  if (token) {
    const name = getStorageName(config);
    sessionStorage.setItem(name, token);
  }
}

function getCSRF(config) {
  const name = getStorageName(config);
  return sessionStorage.getItem(name);
}

function handleError(e) {
  const { data, status, config, headers } = e.response || {};
  const initialCSRF = getCSRF(config);
  const code = data?.code;
  const reqId = headers?.['yoti-request-id'];

  setCSRF(config, headers);
  if (initialCSRF) {
    if (status === 401) {
      navigate(LOGOUT);
    } else if (status === 403) {
      navigate(UNAUTHORISED, { query: { errorCode: code } });
    }
  }

  if (status === 300) {
    throw new ServerError({
      message: ERROR_CODES[code] || ERROR_CODES['F-MULTIPLE-CHOICES'],
      data,
      status,
    });
  }

  throw new Error(
    `${ERROR_CODES[code] || ERROR_CODES['F-SERVER-ERROR']}${
      reqId
        ? `
        ${ERROR_CODES['REQUEST-ID'](reqId)}`
        : ''
    }`
  );
}

function handleRequest(config) {
  const newConfig = { ...config };
  const csrf = getCSRF(config);
  if (csrf) newConfig.headers['x-csrf-token'] = csrf;

  return newConfig;
}

function handleRequestResolve({ config, headers, data }) {
  setCSRF(config, headers);
  return data;
}

export function setupInterceptors() {
  window.addCommonHeader = (key, value) => {
    axios.defaults.headers.common[key] = value;
  };
  axios.interceptors.request.use(handleRequest, handleError);
  axios.interceptors.response.use(handleRequestResolve, handleError);
}
