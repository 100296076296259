import { t } from '@lingui/macro';

export default [
  {
    label: t`Male`,
    value: 'MALE',
  },
  {
    label: t`Female`,
    value: 'FEMALE',
  },
  {
    label: t`Transgender`,
    value: 'TRANSGENDER',
  },
  {
    label: t`Other`,
    value: 'OTHER',
  },
];
