import { h } from 'preact';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { ERROR_CODES } from '../../constant/errorCodes';
import { ConfirmSection } from '../confirm/confirmSection';

export const ErrorSection = ({ error, ...props }) => {
  const wrappedError = Array.isArray(error) ? error : [error];
  return <ConfirmSection {...props} subTexts={wrappedError} canError={false} />;
};

ErrorSection.defaultProps = {
  title: t`Something went wrong`,
  error: ERROR_CODES['F-SERVER-ERROR'],
  confirmMessage: t`Ok`,
  cancelMessage: t`Cancel`,
  'data-qa': 'error-section',
};

ErrorSection.propTypes = {
  title: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  'data-qa': PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  confirmMessage: PropTypes.string,
};
