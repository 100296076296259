import './imageUpload.scss';
import { h } from 'preact';
import { useRef, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '@yoti/ui-kit-components-react/button';
import Icon from '@yoti/ui-kit-components-react/icon';
import { dismiss } from '@yoti/ui-kit-components-react/icons-list';
import { Loading } from '@yoti/ui-kit-components-react/loading';
import iconImage from '../../assets/image.svg';
import useWindowSize, { WINDOW_TYPE } from '../../hooks/windowSize';

const SUPPORTED_FILES = ['image/png', 'image/jpg', 'image/jpeg'];

export const ImageUpload = ({
  onUpload,
  src,
  maxSize,
  buttonUploadText,
  buttonChangeText,
  shouldCaptureNewImage,
}) => {
  const hiddenInput = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [error, setError] = useState('');
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize === WINDOW_TYPE.SMALL;

  const chooseImage = () => {
    hiddenInput.current.value = null;
    hiddenInput.current.click();
  };

  const handleFileUpload = async (files) => {
    if (!files) {
      setError('');
      onUpload();
      return;
    }
    if ([...files].filter(({ size }) => size > maxSize).length) {
      setError(`The max size supported is ${maxSize / 1000}KB`);
      return;
    }
    if ([...files].filter(({ type }) => !SUPPORTED_FILES.includes(type)).length) {
      setError('Unsupported file type');
      return;
    }
    setIsUploading(true);
    await onUpload(files);
    setError('');
    setIsUploading(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'copy';
    setIsHovering(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsHovering(false);
    handleFileUpload(event.dataTransfer.files);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsHovering(false);
  };

  return (
    <div
      className={cn('image-upload', {
        'image-upload--with-src': src,
        'image-upload--hover': isHovering,
        'image-upload--error': error,
        'image-upload--small': isSmallScreen,
      })}
      {...(!isSmallScreen && {
        onDragOver: handleDragOver,
        onDrop: handleDrop,
        onDragLeave: handleDragLeave,
      })}
      data-qa="image-upload"
    >
      {src && (
        <Icon
          className="image-upload__remove"
          component={dismiss}
          onClick={() => handleFileUpload()}
        />
      )}
      <div>
        {error ? (
          <p className="image-upload__error" data-qa="image-upload-error">
            {error}
          </p>
        ) : (
          <img
            className="image-upload__img"
            alt="image upload"
            src={src || iconImage}
            data-qa="image-upload-img"
          />
        )}
      </div>
      {isUploading ? (
        <Loading />
      ) : (
        !isSmallScreen && <p className="image-upload__info">Drag and drop your image or</p>
      )}
      <form noValidate>
        <Button
          onClick={chooseImage}
          disabled={isUploading}
          fullWidth={isSmallScreen}
          data-qa="image-upload-button"
        >
          {src ? buttonChangeText : buttonUploadText}
        </Button>

        <input
          className="image-upload__input"
          type="file"
          ref={hiddenInput}
          accept={SUPPORTED_FILES.map((ext) => ext.replace('image/', '.')).join(',')}
          capture={shouldCaptureNewImage ? 'environment' : undefined}
          onChange={(event) => handleFileUpload(event.target.files)}
          data-qa="image-upload-input"
        />
      </form>
    </div>
  );
};

ImageUpload.defaultProps = {
  src: '',
  maxSize: 200000,
  buttonUploadText: 'Upload image',
  buttonChangeText: 'Change image',
  shouldCaptureNewImage: false,
};

ImageUpload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  src: PropTypes.string,
  maxSize: PropTypes.number,
  buttonUploadText: PropTypes.string,
  buttonChangeText: PropTypes.string,
  shouldCaptureNewImage: PropTypes.bool,
};
