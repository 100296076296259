import PropTypes from 'prop-types';
import { h, Fragment } from 'preact';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import * as DOCUMENT_TYPES from '../../constant/documentTypes';
import { TextField } from '../textField/textField';
import { isDate, required } from '../../services/validators';
import { CountryField } from '../countryField/countryField';

const documentDetailTextOptions = {
  [DOCUMENT_TYPES.PASSPORT]: {
    title: t`Passport details`,
    label: t`Passport number`,
  },
  [DOCUMENT_TYPES.NATIONAL_ID]: {
    title: t`National Id details`,
    label: t`National id number`,
  },
};

export const DocumentSection = ({ documentType, canAutoComplete }) => {
  const { title, label } = documentDetailTextOptions[documentType];

  return (
    <Fragment>
      <h2 className="customer-details-form__section-title">{i18n._(title)}</h2>

      <div className="customer-details-form__field">
        <TextField
          name="documentNumber"
          type="text"
          label={i18n._(label)}
          autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
          validators={[required]}
          data-qa="customer-details-form-document-number"
        />
      </div>

      <div className="customer-details-form__field">
        <CountryField
          name="issuingCountry"
          label={t`Issuing country`}
          validators={[required]}
          data-qa="customer-details-form-issuing-country"
        />
      </div>

      <div className="customer-details-form__field">
        <TextField
          name="expirationDate"
          type="date"
          label={t`Expiry date`}
          autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
          validators={[isDate]}
          placeholder="yyyy-mm-dd"
          data-qa="customer-details-form-expiration-date"
        />
      </div>
    </Fragment>
  );
};

DocumentSection.propTypes = {
  documentType: PropTypes.string.isRequired,
  canAutoComplete: PropTypes.bool,
};

DocumentSection.defaultProps = {
  canAutoComplete: false,
};
