export const getSrc = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target.result) resolve(e.target.result);
      else reject();
    };
    reader.readAsDataURL(file);
  });
};
