import { h, Fragment } from 'preact';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { SelectField } from '@yoti/ui-kit-components-react/select-field';
import { RadioButtons } from '@yoti/ui-kit-components-react/radio-buttons';
import { Field } from 'react-final-form';
import { TextField } from '../textField/textField';
import { required, createFieldValidator } from '../../services/validators';
import { hasExtraDetails } from '../../services/additionalFields';
import { AddressSection } from './addressSection';
import { ETHNICITIES } from '../../constant/ethnicities';
import { VACCINE_STATUS } from '../../constant/vaccineStatus';
import { CountryField } from '../countryField/countryField';
import { getAvailableTestingSchemeOptions } from '../../services/selfRegistrationInvites';

export const OtherDetailsSection = ({ additionalFields, testingSchemes, canAutoComplete }) => {
  if (!hasExtraDetails(additionalFields, testingSchemes)) return null;
  const healthNumberField = additionalFields.find(({ name }) => name === 'health_number');
  const birthNumberField = additionalFields.find(({ name }) => name === 'birth_number');
  const stayingAddressField = additionalFields.find(({ name }) => name === 'staying_address');
  const dateDepartureField = additionalFields.find(({ name }) => name === 'future_departure_date');
  const dateArrivalField = additionalFields.find(({ name }) => name === 'date_of_arrival');
  const travellingFromField = additionalFields.find(({ name }) => name === 'country_from');
  const travellingJourneyField = additionalFields.find(({ name }) => name === 'countries_visited');
  const transportNumberField = additionalFields.find(({ name }) => name === 'flight_number');
  const departureDateField = additionalFields.find(({ name }) => name === 'departure_date');
  const ethnicityField = additionalFields.find(({ name }) => name === 'ethnicity');
  const vaccinationStatusField = additionalFields.find(({ name }) => name === 'vaccination_status');
  const uniqueReferenceField = additionalFields.find(({ name }) => name === 'unique_reference');
  const bookingReferenceField = additionalFields.find(({ name }) => name === 'booking_reference');

  const testingSchemeOptionsTranslated = getAvailableTestingSchemeOptions(testingSchemes);

  return (
    <Fragment>
      <h2 className="customer-details-form__section-title">{t`Other details`}</h2>

      {!!testingSchemeOptionsTranslated.length && (
        <div className="customer-details-form__test-scheme">
          <h3>{t`Type of test`}</h3>
          <Field name="testingScheme">
            {({ input }) => (
              <RadioButtons
                {...input}
                selectedValue={input.value}
                options={testingSchemeOptionsTranslated}
                data-qa="customer-details-form-radio-button"
              />
            )}
          </Field>
        </div>
      )}

      {healthNumberField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="healthNumber" className="customer-details-form__hidden-field" />
          )}
          <TextField
            name="healthNumber"
            label={t`Health number`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
            validators={healthNumberField.optional ? [] : [required]}
            data-qa="customer-details-form-health-number"
          />
        </div>
      )}

      {birthNumberField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="birthNumber" className="customer-details-form__hidden-field" />
          )}
          <TextField
            name="birthNumber"
            label={t`Birth number`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
            validators={birthNumberField.optional ? [] : [required]}
            data-qa="customer-details-form-birth-number"
          />
        </div>
      )}

      {stayingAddressField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="stayingAddress" className="customer-details-form__hidden-field" />
          )}
          <AddressSection
            title={t`Staying Address`}
            type="Staying"
            canAutoComplete={canAutoComplete}
          />
        </div>
      )}

      {dateDepartureField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="dateDeparture" className="customer-details-form__hidden-field" />
          )}
          <TextField
            name="dateDeparture"
            placeholder="yyyy-mm-dd"
            type="date"
            label={t`Date of departure`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
            validators={dateDepartureField.optional ? [] : [required]}
            data-qa="customer-details-form-date-departure"
          />
        </div>
      )}
      {dateArrivalField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="dateArrival" className="customer-details-form__hidden-field" />
          )}
          <TextField
            name="dateArrival"
            placeholder="yyyy-mm-dd"
            type="date"
            label={t`Date of arrival`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
            validators={dateArrivalField.optional ? [] : [required]}
            data-qa="customer-details-form-date-arrival"
          />
        </div>
      )}

      {travellingFromField && (
        <div className="customer-details-form__field">
          <CountryField
            name="travellingFrom"
            label={t`Country or territory you are travelling from`}
            validators={travellingFromField.optional ? [] : [required]}
            data-qa="customer-details-form-travelling-from"
          />
        </div>
      )}

      {travellingJourneyField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="travellingJourney" className="customer-details-form__hidden-field" />
          )}
          <label className="customer-details-form__field-label">
            {t`Countries or territories you have been in as part of the journey:`}
          </label>
          <TextField
            name="travellingJourney"
            label={t`Journey locations`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
            validators={travellingJourneyField.optional ? [] : [required]}
            data-qa="customer-details-form-travelling-journey"
          />
        </div>
      )}

      {transportNumberField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="transportNumber" className="customer-details-form__hidden-field" />
          )}
          <TextField
            name="transportNumber"
            label={t`Flight number, train service, or ship name`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
            validators={transportNumberField.optional ? [] : [required]}
            data-qa="customer-details-form-transport-number"
          />
        </div>
      )}

      {departureDateField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="departureDate" className="customer-details-form__hidden-field" />
          )}
          <label className="customer-details-form__field-label">
            {t`Date you last departed or transitioned through a non-exempt country, territory, or region:`}
          </label>
          <TextField
            name="departureDate"
            placeholder="yyyy-mm-dd"
            type="date"
            label={t`Date departed/transitioned`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
            validators={departureDateField.optional ? [] : [required]}
            data-qa="customer-details-form-departure-date"
          />
        </div>
      )}

      {ethnicityField && (
        <div className="customer-details-form__field">
          <Field
            name="ethnicity"
            validate={ethnicityField.optional ? undefined : createFieldValidator(required)}
          >
            {({ input, meta }) => {
              const error = meta.touched && meta.error;
              return (
                <SelectField
                  {...input}
                  selectedValue={input.value}
                  label={t`Ethnicity`}
                  options={Object.values(ETHNICITIES).map(({ value, label }) => ({
                    value,
                    label: i18n._(label),
                  }))}
                  error={error ? i18n._(error) : undefined}
                  data-qa="customer-details-form-ethnicity"
                />
              );
            }}
          </Field>
        </div>
      )}

      {vaccinationStatusField && (
        <div className="customer-details-form__field">
          <Field
            name="vaccinationStatus"
            validate={vaccinationStatusField.optional ? undefined : createFieldValidator(required)}
          >
            {({ input, meta }) => {
              const error = meta.touched && meta.error;
              return (
                <SelectField
                  {...input}
                  selectedValue={input.value}
                  label={t`Vaccination status`}
                  options={Object.values(VACCINE_STATUS).map(({ value, label }) => ({
                    value,
                    label: i18n._(label),
                  }))}
                  error={error ? i18n._(error) : undefined}
                  data-qa="customer-details-form-vaccination-status"
                />
              );
            }}
          </Field>
        </div>
      )}

      {uniqueReferenceField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="uniqueReference" className="customer-details-form__hidden-field" />
          )}
          <TextField
            name="uniqueReference"
            label={t`Unique reference`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-unique-name'}
            validators={uniqueReferenceField.optional ? [] : [required]}
            data-qa="customer-details-form-unique-reference"
          />
        </div>
      )}

      {bookingReferenceField && (
        <div className="customer-details-form__field">
          {/* another attempt of forcing auto complete to be off */}
          {!canAutoComplete && (
            <input name="bookingReference" className="customer-details-form__hidden-field" />
          )}
          <TextField
            name="bookingReference"
            label={t`Booking reference`}
            autoComplete={canAutoComplete ? 'on' : 'chrome-fix-with-booking-name'}
            validators={bookingReferenceField.optional ? [] : [required]}
            data-qa="customer-details-form-booking-reference"
          />
        </div>
      )}
    </Fragment>
  );
};

OtherDetailsSection.defaultProps = {
  additionalFields: [],
  testingSchemes: [],
  canAutoComplete: false,
};

OtherDetailsSection.propTypes = {
  additionalFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.name,
    })
  ),
  testingSchemes: PropTypes.arrayOf(PropTypes.string),
  canAutoComplete: PropTypes.bool,
};
