import './customerDetailFields.scss';
import { t } from '@lingui/macro';
import { h, Fragment } from 'preact';
import PropTypes from 'prop-types';
import { RadioButtons } from '@yoti/ui-kit-components-react/radio-buttons';
import {
  myData,
  house,
  calendar,
  flag,
  officeBuilding,
  docPassCard,
  email as emailIcon,
  phone as phoneIcon,
  gender as genderIcon,
  docPassport,
  name,
  camera,
  information,
} from '@yoti/ui-kit-components-react/icons-list';
import { ReadOnlyField } from '../readOnlyField/readOnlyField';
import { SwabResult } from '../swabResult/swabResult';
import { TEST_SCHEME_LABELS } from '../../constant/testingSchemes';
import { TEST_OPTIONS_LABELS } from '../../constant/testingOptions';
import getDiffFromObjects from '../../services/getDiffFromObjects';
import { getCountryWithCode } from '../../constant/isoCountries';
import { getImageUrl } from '../../../organisation/services/api';

const getEditedFields = (fields) => {
  if (!fields) {
    return {};
  }

  return fields.reduce(
    (acc, { original, updated }) => ({ ...acc, ...getDiffFromObjects(original, updated) }),
    {}
  );
};

export const CustomerDetailsFields = ({
  fullName,
  givenNames,
  familyName,
  email,
  phone,
  dateOfBirth,
  gender,
  nationality,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  postcode,
  countryIso,
  documentType,
  documentNumber,
  issuingCountry,
  expirationDate,
  healthNumber,
  birthNumber,
  addressLine1Staying,
  addressLine2Staying,
  addressLine3Staying,
  addressLine4Staying,
  postcodeStaying,
  countryIsoStaying,
  dateArrival,
  dateDeparture,
  travellingFrom,
  travellingJourney,
  transportNumber,
  departureDate,
  ethnicity,
  vaccinationStatus,
  updated,
  overallResult,
  resultCreated,
  testingScheme,
  uniqueReference,
  bookingReference,
  imageId,
  imageIds,
  edits,
  status,
  type,
}) => {
  const editedFields = getEditedFields(edits);

  return (
    <div className="customer-detail-fields">
      {status && (
        <ReadOnlyField label="Status" icon={information}>
          {status}
        </ReadOnlyField>
      )}
      {type && TEST_OPTIONS_LABELS[type] && (
        <ReadOnlyField label="Test type" icon={information}>
          {TEST_OPTIONS_LABELS[type]}
        </ReadOnlyField>
      )}
      {fullName && (
        <ReadOnlyField label={t`Full name`} isEdited={editedFields.full_name} icon={name}>
          {fullName}
        </ReadOnlyField>
      )}
      {givenNames && (
        <ReadOnlyField label={t`Given name(s)`} isEdited={editedFields.given_names} icon={calendar}>
          {givenNames}
        </ReadOnlyField>
      )}
      {familyName && (
        <ReadOnlyField label={t`Family name`} isEdited={editedFields.family_name} icon={name}>
          {familyName}
        </ReadOnlyField>
      )}
      {email && (
        <ReadOnlyField label={t`Email`} isEdited={editedFields.email} icon={emailIcon}>
          {email}
        </ReadOnlyField>
      )}
      {phone && (
        <ReadOnlyField label={t`Phone`} isEdited={editedFields.phone} icon={phoneIcon}>
          {phone}
        </ReadOnlyField>
      )}
      {dateOfBirth && (
        <ReadOnlyField
          label={t`Date of birth`}
          isEdited={editedFields.date_of_birth}
          icon={calendar}
        >
          {dateOfBirth}
        </ReadOnlyField>
      )}
      {gender && (
        <ReadOnlyField label={t`Gender`} isEdited={editedFields.gender} icon={genderIcon}>
          {gender}
        </ReadOnlyField>
      )}
      {nationality && (
        <ReadOnlyField label={t`Nationality`} isEdited={editedFields.nationality} icon={flag}>
          {getCountryWithCode(nationality)}
        </ReadOnlyField>
      )}

      {addressLine1 && (
        <ReadOnlyField label={t`Address line 1`} icon={house}>
          {addressLine1}
        </ReadOnlyField>
      )}
      {addressLine2 && (
        <ReadOnlyField label={t`Address line 2`} icon={house}>
          {addressLine2}
        </ReadOnlyField>
      )}
      {addressLine3 && (
        <ReadOnlyField label={t`Address line 3`} icon={house}>
          {addressLine3}
        </ReadOnlyField>
      )}
      {addressLine4 && (
        <ReadOnlyField label={t`City`} icon={house}>
          {addressLine4}
        </ReadOnlyField>
      )}
      {postcode && (
        <ReadOnlyField label={t`Postcode`} isEdited={editedFields.postal_code} icon={house}>
          {postcode}
        </ReadOnlyField>
      )}
      {countryIso && (
        <ReadOnlyField label={t`Country/Region`} isEdited={editedFields.country_iso} icon={house}>
          {getCountryWithCode(countryIso)}
        </ReadOnlyField>
      )}

      {documentType && (
        <ReadOnlyField
          label={t`Document type`}
          isEdited={editedFields.document_type}
          icon={docPassport}
        >
          {documentType}
        </ReadOnlyField>
      )}
      {documentNumber && (
        <ReadOnlyField
          label={t`Document number`}
          isEdited={editedFields.document_number}
          icon={docPassport}
        >
          {documentNumber}
        </ReadOnlyField>
      )}
      {issuingCountry && (
        <ReadOnlyField
          label={t`Issuing country`}
          isEdited={editedFields.issuing_country}
          icon={docPassport}
        >
          {getCountryWithCode(issuingCountry)}
        </ReadOnlyField>
      )}
      {expirationDate && (
        <ReadOnlyField
          label={t`Expiry date`}
          isEdited={editedFields.expiration_date}
          icon={calendar}
        >
          {expirationDate}
        </ReadOnlyField>
      )}

      {testingScheme && (
        <>
          <label className="customer-detail-fields__label">{t`Type of test`}</label>
          <RadioButtons
            selectedValue={testingScheme}
            options={[{ label: TEST_SCHEME_LABELS[testingScheme], value: testingScheme }]}
            disabled
          />
        </>
      )}

      {healthNumber && (
        <ReadOnlyField label={t`Health number`} isEdited={editedFields.health_number} icon={myData}>
          {healthNumber}
        </ReadOnlyField>
      )}
      {birthNumber && (
        <ReadOnlyField label={t`Birth number`} isEdited={editedFields.birth_number} icon={myData}>
          {birthNumber}
        </ReadOnlyField>
      )}
      {addressLine1Staying && (
        <ReadOnlyField label={t`Staying address line 1`} icon={house}>
          {addressLine1Staying}
        </ReadOnlyField>
      )}
      {addressLine2Staying && (
        <ReadOnlyField label={t`Staying address line 2`} icon={house}>
          {addressLine2Staying}
        </ReadOnlyField>
      )}
      {addressLine3Staying && (
        <ReadOnlyField label={t`Staying address line 3`} icon={house}>
          {addressLine3Staying}
        </ReadOnlyField>
      )}
      {addressLine4Staying && (
        <ReadOnlyField label={t`Staying city`} icon={house}>
          {addressLine4Staying}
        </ReadOnlyField>
      )}
      {postcodeStaying && (
        <ReadOnlyField label={t`Staying postcode`} icon={house}>
          {postcodeStaying}
        </ReadOnlyField>
      )}
      {countryIsoStaying && (
        <ReadOnlyField label={t`Staying country/region`} icon={house}>
          {getCountryWithCode(countryIsoStaying)}
        </ReadOnlyField>
      )}
      {dateArrival && (
        <ReadOnlyField
          label={t`Date of arrival`}
          isEdited={editedFields.date_of_arrival}
          icon={calendar}
        >
          {dateArrival}
        </ReadOnlyField>
      )}
      {dateDeparture && (
        <ReadOnlyField
          label={t`Date of departure`}
          isEdited={editedFields.future_departure_date}
          icon={calendar}
        >
          {dateDeparture}
        </ReadOnlyField>
      )}
      {travellingFrom && (
        <ReadOnlyField label={t`Country or territory you are travelling from`} icon={flag}>
          {getCountryWithCode(travellingFrom)}
        </ReadOnlyField>
      )}
      {travellingJourney && (
        <ReadOnlyField
          label={t`Countries or territories you have been in as part of the journey`}
          icon={flag}
        >
          {travellingJourney}
        </ReadOnlyField>
      )}
      {transportNumber && (
        <ReadOnlyField label={t`Flight number, train service, or ship name`} icon={officeBuilding}>
          {transportNumber}
        </ReadOnlyField>
      )}
      {departureDate && (
        <ReadOnlyField
          label={t`Date you last departed or transitioned through a non-exempt country, territory, or region`}
          icon={calendar}
        >
          {departureDate}
        </ReadOnlyField>
      )}
      {ethnicity && (
        <ReadOnlyField label={t`Ethnicity`} icon={myData}>
          {ethnicity}
        </ReadOnlyField>
      )}
      {vaccinationStatus && (
        <ReadOnlyField label={t`Vaccination status`} icon={docPassCard}>
          {vaccinationStatus}
        </ReadOnlyField>
      )}
      {uniqueReference && (
        <ReadOnlyField
          label={t`Unique reference`}
          isEdited={editedFields.unique_reference}
          icon={officeBuilding}
        >
          {uniqueReference}
        </ReadOnlyField>
      )}
      {bookingReference && (
        <ReadOnlyField
          label={t`Booking reference`}
          isEdited={editedFields.booking_reference}
          icon={officeBuilding}
        >
          {bookingReference}
        </ReadOnlyField>
      )}
      {imageId && (
        <ReadOnlyField label={t`Photo`} icon={camera}>
          <img
            src={getImageUrl(imageId)}
            alt={t`patient LFT test`}
            className="customer-detail-fields__img"
          />
        </ReadOnlyField>
      )}
      {imageIds &&
        imageIds.map((imgId) => (
          <ReadOnlyField key={imgId} label={t`Photo`} icon={camera}>
            <img
              src={getImageUrl(imgId)}
              alt={t`patient LFT test`}
              className="customer-detail-fields__img"
            />
          </ReadOnlyField>
        ))}

      {(overallResult || overallResult === '') && (
        <Fragment>
          {updated && (
            <ReadOnlyField label={t`Details date updated`} icon={calendar}>
              {updated}
            </ReadOnlyField>
          )}

          <ReadOnlyField label={t`Test Result`} icon={myData}>
            <div className="more-details-customer__result">
              <SwabResult value={overallResult} />
            </div>
          </ReadOnlyField>

          {resultCreated && (
            <ReadOnlyField label={t`Test date updated`} icon={calendar}>
              {resultCreated}
            </ReadOnlyField>
          )}
        </Fragment>
      )}
    </div>
  );
};

CustomerDetailsFields.defaultProps = {
  fullName: '',
  givenNames: '',
  familyName: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  gender: '',
  nationality: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  postcode: '',
  countryIso: '',
  documentType: '',
  documentNumber: '',
  issuingCountry: '',
  expirationDate: '',
  healthNumber: '',
  birthNumber: '',
  addressLine1Staying: '',
  addressLine2Staying: '',
  addressLine3Staying: '',
  addressLine4Staying: '',
  postcodeStaying: '',
  countryIsoStaying: '',
  dateArrival: '',
  travellingFrom: '',
  travellingJourney: '',
  transportNumber: '',
  departureDate: '',
  ethnicity: '',
  vaccinationStatus: '',
  updated: '',
  overallResult: null,
  resultCreated: '',
  testingScheme: '',
};

CustomerDetailsFields.propTypes = {
  fullName: PropTypes.string,
  givenNames: PropTypes.string,
  familyName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  dateOfBirth: PropTypes.string,
  gender: PropTypes.string,
  nationality: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  addressLine4: PropTypes.string,
  postcode: PropTypes.string,
  countryIso: PropTypes.string,
  documentType: PropTypes.string,
  documentNumber: PropTypes.string,
  issuingCountry: PropTypes.string,
  expirationDate: PropTypes.string,
  healthNumber: PropTypes.string,
  birthNumber: PropTypes.string,
  addressLine1Staying: PropTypes.string,
  addressLine2Staying: PropTypes.string,
  addressLine3Staying: PropTypes.string,
  addressLine4Staying: PropTypes.string,
  postcodeStaying: PropTypes.string,
  countryIsoStaying: PropTypes.string,
  dateArrival: PropTypes.string,
  travellingFrom: PropTypes.string,
  travellingJourney: PropTypes.string,
  transportNumber: PropTypes.string,
  departureDate: PropTypes.string,
  ethnicity: PropTypes.string,
  vaccinationStatus: PropTypes.string,
  updated: PropTypes.string,
  overallResult: PropTypes.string,
  resultCreated: PropTypes.string,
  testingScheme: PropTypes.string,
};
