import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { ErrorSection } from '../errorSection/errorSection';

class ErrorBoundary extends Component {
  state = { error: '' };

  static getDerivedStateFromError({ message } = {}) {
    return { error: message };
  }

  render() {
    if (this.state.error) return <ErrorSection />;
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
