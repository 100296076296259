import { h } from 'preact';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { i18n } from '@lingui/core';
import {
  TextField as SimpleTextField,
  DateField,
  PasswordField,
} from '@yoti/ui-kit-components-react/text-field';
import { createFieldValidator } from '../../services/validators';

export const TextField = ({ name, validators, convertValue, initialValue, label, ...props }) => {
  let Input = props.type === 'date' ? DateField : SimpleTextField;
  if (props.type === 'password') Input = PasswordField;

  return (
    <Field name={name} initialValue={initialValue} validate={createFieldValidator(...validators)}>
      {({ input, meta }) => {
        const errorStr = meta.error || (!meta.submitting && meta.submitError) || '';
        const error = meta.touched && errorStr && i18n._(errorStr);
        const labelTr = label && i18n._(label);
        return (
          <Input
            {...input}
            {...props}
            onChange={(value) => input.onChange(convertValue(value))}
            label={labelTr}
            error={error}
          />
        );
      }}
    </Field>
  );
};

TextField.defaultProps = {
  validators: [],
  type: undefined,
  convertValue: (value) => value,
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  convertValue: PropTypes.func,
};
