export const getReader = async () => {
  const { BrowserMultiFormatReader } = await import('@zxing/browser');
  return new BrowserMultiFormatReader();
};

export const getCameraStream = () => {
  return navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
};

export const scan = async (video) => {
  const reader = await getReader();
  const stream = await getCameraStream();

  return reader.decodeOnceFromStream(stream, video);
};

export const getImageDimensions = (clientWidth, clientHeight, maxDimension) => {
  if (!maxDimension) return { width: clientWidth, height: clientHeight };

  let scale = 1;
  if (clientHeight >= maxDimension || clientWidth >= maxDimension) {
    scale = Math.min(maxDimension / clientWidth, maxDimension / clientHeight);
  }
  const width = scale * clientWidth;
  const height = scale * clientHeight;

  return { width, height };
};
