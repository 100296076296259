import { h } from 'preact';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { SelectField } from '@yoti/ui-kit-components-react/select-field';
import { i18n } from '@lingui/core';
import { ALPHA_SORTED_COUNTRIES } from '../../constant/isoCountries';
import { createFieldValidator } from '../../services/validators';

export const CountryField = ({ name, initialValue, validators, ...props }) => (
  <Field name={name} initialValue={initialValue} validate={createFieldValidator(...validators)}>
    {({ input, meta }) => {
      const error = meta.touched && meta.error && i18n._(meta.error);
      return (
        <SelectField
          {...input}
          options={ALPHA_SORTED_COUNTRIES.map((isoCountry) => ({
            value: isoCountry.iso,
            label: i18n._(isoCountry.country),
          }))}
          selectedValue={input.value}
          error={error || undefined}
          {...props}
        />
      );
    }}
  </Field>
);

CountryField.defaultProps = {
  initialValue: null,
  validators: [],
};

CountryField.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
};
