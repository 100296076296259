import { t } from '@lingui/macro';

export const ISO_COUNTRIES = [
  {
    iso: 'ASM',
    country: t`American Samoa`,
  },
  {
    iso: 'ATA',
    country: t`Antarctica`,
  },
  {
    iso: 'ATF',
    country: t`French Southern Territories`,
  },
  {
    iso: 'ATG',
    country: t`Antigua and Barbuda`,
  },
  {
    iso: 'BDI',
    country: t`Burundi`,
  },
  {
    iso: 'BES',
    country: t`Bonaire, Sint Eustatius and Saba`,
  },
  {
    iso: 'BIH',
    country: t`Bosnia and Herzegovina`,
  },
  {
    iso: 'BLM',
    country: t`Saint Barthélemy`,
  },
  {
    iso: 'BVT',
    country: t`Bouvet Island`,
  },
  {
    iso: 'CCK',
    country: t`Cocos (Keeling) Islands`,
  },
  {
    iso: 'CIV',
    country: t`Côte d’Ivoire`,
  },
  {
    iso: 'COD',
    country: t`The Democratic Republic of the Congo`,
  },
  {
    iso: 'COG',
    country: t`Congo`,
  },
  {
    iso: 'COK',
    country: t`Cook Islands`,
  },
  {
    iso: 'CPV',
    country: t`Cabo Verde`,
  },
  {
    iso: 'CUW',
    country: t`Curaçao Antillian`,
  },
  {
    iso: 'CXR',
    country: t`Christmas Island`,
  },
  {
    iso: 'ERI',
    country: t`Eritrea`,
  },
  {
    iso: 'ESH',
    country: t`Western Sahara`,
  },
  {
    iso: 'FLK',
    country: t`Falkland Islands (Malvinas)`,
  },
  {
    iso: 'FRO',
    country: t`Faroe Islands`,
  },
  {
    iso: 'GIN',
    country: t`Guinea`,
  },
  {
    iso: 'GLP',
    country: t`Guadeloupe`,
  },
  {
    iso: 'GNB',
    country: t`Guinea-Bissau`,
  },
  {
    iso: 'GUF',
    country: t`French Guiana`,
  },
  {
    iso: 'GUM',
    country: t`Guam`,
  },
  {
    iso: 'HMD',
    country: t`Heard Island and McDonald Islands`,
  },
  {
    iso: 'IRN',
    country: t`Islamic Republic of Iran`,
  },
  {
    iso: 'IRQ',
    country: t`Iraq`,
  },
  {
    iso: 'KNA',
    country: t`Saint Kitts and Nevis`,
  },
  {
    iso: 'LBN',
    country: t`Lebanon`,
  },
  {
    iso: 'LBR',
    country: t`Liberia`,
  },
  {
    iso: 'LBY',
    country: t`Libya`,
  },
  {
    iso: 'LCA',
    country: t`Saint Lucia`,
  },
  {
    iso: 'LIE',
    country: t`Liechtenstein`,
  },
  {
    iso: 'MAF',
    country: t`Saint Martin (French part)`,
  },
  {
    iso: 'MHL',
    country: t`Marshall Islands`,
  },
  {
    iso: 'MNG',
    country: t`Mongolia`,
  },
  {
    iso: 'MNP',
    country: t`Northern Mariana Islands`,
  },
  {
    iso: 'MTQ',
    country: t`Martinique`,
  },
  {
    iso: 'MYT',
    country: t`Mayotte`,
  },
  {
    iso: 'NCL',
    country: t`New Caledonia`,
  },
  {
    iso: 'NFK',
    country: t`Norfolk Island`,
  },
  {
    iso: 'NIU',
    country: t`Niue`,
  },
  {
    iso: 'PCN',
    country: t`Pitcairn`,
  },
  {
    iso: 'PRI',
    country: t`Puerto Rico`,
  },
  {
    iso: 'PRK',
    country: t`Democratic People's Republic of Korea North`,
  },
  {
    iso: 'PYF',
    country: t`French Polynesia`,
  },
  {
    iso: 'REU',
    country: t`Réunion`,
  },
  {
    iso: 'SDN',
    country: t`Sudan`,
  },
  {
    iso: 'SJM',
    country: t`Svalbard and Jan Mayen`,
  },
  {
    iso: 'SLV',
    country: t`El Salvador`,
  },
  {
    iso: 'SOM',
    country: t`Somalia`,
  },
  {
    iso: 'SPM',
    country: t`Saint Pierre and Miquelon`,
  },
  {
    iso: 'SSD',
    country: t`South Sudan`,
  },
  {
    iso: 'STP',
    country: t`Sao Tome and Principe`,
  },
  {
    iso: 'SWZ',
    country: t`Swaziland`,
  },
  {
    iso: 'SXM',
    country: t`Sint Maarten (Dutch part)`,
  },
  {
    iso: 'SYR',
    country: t`Syrian Arab Republic`,
  },
  {
    iso: 'TCA',
    country: t`Turks and Caicos Islands`,
  },
  {
    iso: 'TKL',
    country: t`Tokelau`,
  },
  {
    iso: 'TLS',
    country: t`Timor-Leste`,
  },
  {
    iso: 'UMI',
    country: t`United States Minor Outlying Islands`,
  },
  {
    iso: 'VAT',
    country: t`Holy See (Vatican City State)`,
  },
  {
    iso: 'VCT',
    country: t`Saint Vincent and the Grenadines`,
  },
  {
    iso: 'VIR',
    country: t`Virgin Islands, U.S.`,
  },
  {
    iso: 'WLF',
    country: t`Wallis and Futuna`,
  },
  {
    iso: 'YEM',
    country: t`Yemen`,
  },
  {
    iso: 'AFG',
    country: t`Afghanistan`,
  },
  {
    iso: 'ALA',
    country: t`Åland Islands`,
  },
  {
    iso: 'AND',
    country: t`Andorra`,
  },
  {
    iso: 'ABW',
    country: t`Aruba`,
  },
  {
    iso: 'ARE',
    country: t`United Arab Emirates`,
  },
  {
    iso: 'AIA',
    country: t`Anguilla`,
  },
  {
    iso: 'ALB',
    country: t`Albania`,
  },
  {
    iso: 'ARM',
    country: t`Armenia`,
  },
  {
    iso: 'AGO',
    country: t`Angola`,
  },
  {
    iso: 'ARG',
    country: t`Argentina`,
  },
  {
    iso: 'AUT',
    country: t`Austria`,
  },
  {
    iso: 'AUS',
    country: t`Australia`,
  },
  {
    iso: 'AZE',
    country: t`Azerbaijan`,
  },
  {
    iso: 'BRB',
    country: t`Barbados`,
  },
  {
    iso: 'BGD',
    country: t`Bangladesh`,
  },
  {
    iso: 'BEL',
    country: t`Belgium`,
  },
  {
    iso: 'BFA',
    country: t`Burkina Faso`,
  },
  {
    iso: 'BGR',
    country: t`Bulgaria`,
  },
  {
    iso: 'BHR',
    country: t`Bahrain`,
  },
  {
    iso: 'BEN',
    country: t`Benin`,
  },
  {
    iso: 'BMU',
    country: t`Bermuda`,
  },
  {
    iso: 'BRN',
    country: t`Brunei Darussalam`,
  },
  {
    iso: 'BOL',
    country: t`Bolivia`,
  },
  {
    iso: 'BRA',
    country: t`Brazil`,
  },
  {
    iso: 'BHS',
    country: t`Bahamas`,
  },
  {
    iso: 'BTN',
    country: t`Bhutan`,
  },
  {
    iso: 'BWA',
    country: t`Botswana`,
  },
  {
    iso: 'BLR',
    country: t`Belarus`,
  },
  {
    iso: 'BLZ',
    country: t`Belize`,
  },
  {
    iso: 'CAN',
    country: t`Canada`,
  },
  {
    iso: 'CAF',
    country: t`Central African Republic`,
  },
  {
    iso: 'CHE',
    country: t`Switzerland`,
  },
  {
    iso: 'CHL',
    country: t`Chile`,
  },
  {
    iso: 'CMR',
    country: t`Cameroon`,
  },
  {
    iso: 'CHN',
    country: t`China`,
  },
  {
    iso: 'COL',
    country: t`Colombia`,
  },
  {
    iso: 'CRI',
    country: t`Costa Rica`,
  },
  {
    iso: 'CUB',
    country: t`Cuba`,
  },
  {
    iso: 'CYP',
    country: t`Cyprus`,
  },
  {
    iso: 'CZE',
    country: t`Czech Republic`,
  },
  {
    iso: 'DEU',
    country: t`Germany`,
  },
  {
    iso: 'DJI',
    country: t`Djibouti`,
  },
  {
    iso: 'DNK',
    country: t`Denmark`,
  },
  {
    iso: 'DMA',
    country: t`Dominica`,
  },
  {
    iso: 'DOM',
    country: t`Dominican Republic`,
  },
  {
    iso: 'DZA',
    country: t`Algeria`,
  },
  {
    iso: 'ECU',
    country: t`Ecuador`,
  },
  {
    iso: 'EST',
    country: t`Estonia`,
  },
  {
    iso: 'EGY',
    country: t`Egypt`,
  },
  {
    iso: 'ESP',
    country: t`Spain`,
  },
  {
    iso: 'ETH',
    country: t`Ethiopia`,
  },
  {
    iso: 'FIN',
    country: t`Finland`,
  },
  {
    iso: 'FJI',
    country: t`Fiji`,
  },
  {
    iso: 'FSM',
    country: t`Federated States of Micronesia`,
  },
  {
    iso: 'FRA',
    country: t`France`,
  },
  {
    iso: 'GAB',
    country: t`Gabon`,
  },
  {
    iso: 'GBR',
    country: t`United Kingdom`,
  },
  {
    iso: 'GRD',
    country: t`Grenada`,
  },
  {
    iso: 'GEO',
    country: t`Georgia`,
  },
  {
    iso: 'GGY',
    country: t`Guernsey`,
  },
  {
    iso: 'GHA',
    country: t`Ghana`,
  },
  {
    iso: 'GIB',
    country: t`Gibraltar`,
  },
  {
    iso: 'GRL',
    country: t`Greenland`,
  },
  {
    iso: 'GMB',
    country: t`Gambia`,
  },
  {
    iso: 'GNQ',
    country: t`Equatorial Guinea`,
  },
  {
    iso: 'GRC',
    country: t`Greece`,
  },
  {
    iso: 'GTM',
    country: t`Guatemala`,
  },
  {
    iso: 'GUY',
    country: t`Guyana`,
  },
  {
    iso: 'HKG',
    country: t`Hong Kong`,
  },
  {
    iso: 'HND',
    country: t`Honduras`,
  },
  {
    iso: 'HRV',
    country: t`Croatia`,
  },
  {
    iso: 'HTI',
    country: t`Haiti`,
  },
  {
    iso: 'HUN',
    country: t`Hungary`,
  },
  {
    iso: 'IDN',
    country: t`Indonesia`,
  },
  {
    iso: 'IMN',
    country: t`Isle of Man`,
  },
  {
    iso: 'IOT',
    country: t`British Indian Ocean Territory`,
  },
  {
    iso: 'IRL',
    country: t`Ireland`,
  },
  {
    iso: 'ISR',
    country: t`Israel`,
  },
  {
    iso: 'IND',
    country: t`India`,
  },
  {
    iso: 'ISL',
    country: t`Iceland`,
  },
  {
    iso: 'ITA',
    country: t`Italy`,
  },
  {
    iso: 'JEY',
    country: t`Jersey`,
  },
  {
    iso: 'JAM',
    country: t`Jamaica`,
  },
  {
    iso: 'JOR',
    country: t`Jordan`,
  },
  {
    iso: 'JPN',
    country: t`Japan`,
  },
  {
    iso: 'KEN',
    country: t`Kenya`,
  },
  {
    iso: 'KGZ',
    country: t`Kyrgyzstan`,
  },
  {
    iso: 'KHM',
    country: t`Cambodia`,
  },
  {
    iso: 'KIR',
    country: t`Kiribati`,
  },
  {
    iso: 'COM',
    country: t`Comoros`,
  },
  {
    iso: 'KOR',
    country: t`Republic of Korea`,
  },
  {
    iso: 'KWT',
    country: t`Kuwait`,
  },
  {
    iso: 'CYM',
    country: t`Cayman Islands`,
  },
  {
    iso: 'KAZ',
    country: t`Kazakhstan`,
  },
  {
    iso: 'LAO',
    country: t`Lao People's Democratic Republic`,
  },
  {
    iso: 'LKA',
    country: t`Sri Lanka`,
  },
  {
    iso: 'LSO',
    country: t`Lesotho`,
  },
  {
    iso: 'LTU',
    country: t`Lithuania`,
  },
  {
    iso: 'LUX',
    country: t`Luxembourg`,
  },
  {
    iso: 'LVA',
    country: t`Latvia`,
  },
  {
    iso: 'MAR',
    country: t`Morocco`,
  },
  {
    iso: 'MCO',
    country: t`Monaco`,
  },
  {
    iso: 'MNE',
    country: t`Montenegro`,
  },
  {
    iso: 'MDG',
    country: t`Madagascar`,
  },
  {
    iso: 'MKD',
    country: t`Macedonia`,
  },
  {
    iso: 'MLI',
    country: t`Mali`,
  },
  {
    iso: 'MMR',
    country: t`Myanmar`,
  },
  {
    iso: 'MAC',
    country: t`Macao`,
  },
  {
    iso: 'MRT',
    country: t`Mauritania`,
  },
  {
    iso: 'MSR',
    country: t`Montserrat`,
  },
  {
    iso: 'MLT',
    country: t`Malta`,
  },
  {
    iso: 'MUS',
    country: t`Mauritius`,
  },
  {
    iso: 'MDV',
    country: t`Maldives`,
  },
  {
    iso: 'MWI',
    country: t`Malawi`,
  },
  {
    iso: 'MEX',
    country: t`Mexico`,
  },
  {
    iso: 'MYS',
    country: t`Malaysia`,
  },
  {
    iso: 'MDA',
    country: t`Moldova, Republic of`,
  },
  {
    iso: 'MOZ',
    country: t`Mozambique`,
  },
  {
    iso: 'NAM',
    country: t`Namibia`,
  },
  {
    iso: 'NER',
    country: t`Niger`,
  },
  {
    iso: 'NGA',
    country: t`Nigeria`,
  },
  {
    iso: 'NIC',
    country: t`Nicaragua`,
  },
  {
    iso: 'NLD',
    country: t`Netherlands`,
  },
  {
    iso: 'NOR',
    country: t`Norway`,
  },
  {
    iso: 'NPL',
    country: t`Nepal`,
  },
  {
    iso: 'NRU',
    country: t`Nauru`,
  },
  {
    iso: 'NZL',
    country: t`New Zealand`,
  },
  {
    iso: 'OMN',
    country: t`Oman`,
  },
  {
    iso: 'PAN',
    country: t`Panama`,
  },
  {
    iso: 'PER',
    country: t`Peru`,
  },
  {
    iso: 'PNG',
    country: t`Papua New Guinea`,
  },
  {
    iso: 'PHL',
    country: t`Philippines`,
  },
  {
    iso: 'PAK',
    country: t`Pakistan`,
  },
  {
    iso: 'POL',
    country: t`Poland`,
  },
  {
    iso: 'PSE',
    country: t`State of Palestine`,
  },
  {
    iso: 'PRT',
    country: t`Portugal`,
  },
  {
    iso: 'PLW',
    country: t`Palau`,
  },
  {
    iso: 'PRY',
    country: t`Paraguay`,
  },
  {
    iso: 'QAT',
    country: t`Qatar`,
  },
  {
    iso: 'ROU',
    country: t`Romania`,
  },
  {
    iso: 'SRB',
    country: t`Serbia`,
  },
  {
    iso: 'RUS',
    country: t`Russian Federation`,
  },
  {
    iso: 'RWA',
    country: t`Rwanda`,
  },
  {
    iso: 'SAU',
    country: t`Saudi Arabia`,
  },
  {
    iso: 'SGS',
    country: t`South Georgia and the South Sandwich`,
  },
  {
    iso: 'SHN',
    country: t`Saint Helena, Ascension and Tristan da Cunha`,
  },
  {
    iso: 'SLB',
    country: t`Solomon Islands`,
  },
  {
    iso: 'SYC',
    country: t`Seychelles`,
  },
  {
    iso: 'SWE',
    country: t`Sweden`,
  },
  {
    iso: 'SGP',
    country: t`Singapore`,
  },
  {
    iso: 'SVN',
    country: t`Slovenia`,
  },
  {
    iso: 'SVK',
    country: t`Slovakia`,
  },
  {
    iso: 'SLE',
    country: t`Sierra Leone`,
  },
  {
    iso: 'SMR',
    country: t`San Marino`,
  },
  {
    iso: 'SEN',
    country: t`Senegal`,
  },
  {
    iso: 'SUR',
    country: t`Suriname`,
  },
  {
    iso: 'TCD',
    country: t`Chad`,
  },
  {
    iso: 'TGO',
    country: t`Togo`,
  },
  {
    iso: 'THA',
    country: t`Thailand`,
  },
  {
    iso: 'TJK',
    country: t`Tajikistan`,
  },
  {
    iso: 'TKM',
    country: t`Turkmenistan`,
  },
  {
    iso: 'TUN',
    country: t`Tunisia`,
  },
  {
    iso: 'TON',
    country: t`Tonga`,
  },
  {
    iso: 'TUR',
    country: t`Turkey`,
  },
  {
    iso: 'TTO',
    country: t`Trinidad and Tobago`,
  },
  {
    iso: 'TUV',
    country: t`Tuvalu`,
  },
  {
    iso: 'TWN',
    country: t`Taiwan, Province of China`,
  },
  {
    iso: 'TZA',
    country: t`United Republic of Tanzania`,
  },
  {
    iso: 'UKR',
    country: t`Ukraine`,
  },
  {
    iso: 'UGA',
    country: t`Uganda`,
  },
  {
    iso: 'USA',
    country: t`United States`,
  },
  {
    iso: 'URY',
    country: t`Uruguay`,
  },
  {
    iso: 'UZB',
    country: t`Uzbekistan`,
  },
  {
    iso: 'VEN',
    country: t`Bolivarian Republic of Venezuela`,
  },
  {
    iso: 'VGB',
    country: t`British Virgin Islands`,
  },
  {
    iso: 'VNM',
    country: t`Vietnam`,
  },
  {
    iso: 'VUT',
    country: t`Vanuatu`,
  },
  {
    iso: 'WSM',
    country: t`Samoa`,
  },
  {
    iso: 'RKS',
    country: t`Kosovo`,
  },
  {
    iso: 'ZAF',
    country: t`South Africa`,
  },
  {
    iso: 'ZMB',
    country: t`Zambia`,
  },
  {
    iso: 'ZWE',
    country: t`Zimbabwe`,
  },
];

export const ALPHA_SORTED_COUNTRIES = ISO_COUNTRIES.sort((a, b) =>
  a.country.localeCompare(b.country)
);

export const getCountryWithCode = (code) => ISO_COUNTRIES.find(({ iso }) => iso === code)?.country;

export default ISO_COUNTRIES;
