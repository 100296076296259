import './confirmSection.scss';
import { h } from 'preact';
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import cn from 'classnames';
import { Loading } from '@yoti/ui-kit-components-react/loading';
import { Button as SimpleButton, BUTTON_VARIATIONS } from '@yoti/ui-kit-components-react/button';
import { withError } from '@yoti/ui-kit-components-react/hoc';
import Icon from '@yoti/ui-kit-components-react/icon';

export const ConfirmSection = ({
  title,
  subheader,
  subTexts,
  text,
  confirmMessage,
  confirmVariation,
  cancelMessage,
  onConfirm,
  onCancel,
  iconComponent,
  leftAlign,
  canError,
  'data-qa': dataQa,
}) => {
  const [confirmErrors, setConfirmErrors] = useState();
  const [cancelErrors, setCancelErrors] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const Button = canError ? withError(SimpleButton) : SimpleButton;

  const handleAsyncConfirm = async (...args) => {
    try {
      setIsUpdating(true);
      return await onConfirm(...args);
    } catch ({ message }) {
      setConfirmErrors([message]);
      setIsUpdating(false);
    }
    return null;
  };

  const handleAsyncCancel = async (...args) => {
    try {
      setIsUpdating(true);
      return await onCancel(...args);
    } catch ({ message }) {
      setCancelErrors([message]);
      setIsUpdating(false);
    }
    return null;
  };

  if (isUpdating) return <Loading />;

  return (
    <div
      className={cn('confirm-section', { 'confirm-section--left-align': leftAlign })}
      data-qa={dataQa}
    >
      {iconComponent && <Icon className="confirm-section__title-icon" component={iconComponent} />}
      <h2 data-qa={`${dataQa}-title`} className="confirm-section__title">
        {i18n._(title) || t`Are you sure?`}
      </h2>

      {text && (
        <p className="confirm-section__main-text" data-qa={`${dataQa}-message`}>
          {i18n._(text)}
        </p>
      )}

      {subheader && <h4 className="confirm-section__subtitle">{subheader}</h4>}
      {subTexts.map((subText) => (
        <p key={subText} className="confirm-section__text" data-qa={`${dataQa}-message`}>
          {i18n._(subText)}
        </p>
      ))}

      {onConfirm && (
        <Button
          onClick={canError ? handleAsyncConfirm : onConfirm}
          variation={confirmVariation}
          errors={confirmErrors}
          autoFocus
          fullWidth
          data-qa={`${dataQa}-button`}
        >
          {confirmMessage ? i18n._(confirmMessage) : t`Confirm`}
        </Button>
      )}

      {onCancel && (
        <div className="confirm-section__cancel">
          <Button
            onClick={canError ? handleAsyncCancel : onCancel}
            variation={BUTTON_VARIATIONS.SECONDARY}
            errors={cancelErrors}
            fullWidth
            data-qa={`${dataQa}-cancel`}
          >
            {cancelMessage ? i18n._(cancelMessage) : t`Cancel`}
          </Button>
        </div>
      )}
    </div>
  );
};

ConfirmSection.defaultProps = {
  onConfirm: undefined,
  onCancel: undefined,
  title: '',
  subheader: '',
  text: '',
  subTexts: [],
  confirmMessage: '',
  cancelMessage: '',
  iconComponent: null,
  confirmVariation: BUTTON_VARIATIONS.PRIMARY,
  leftAlign: false,
  canError: true,
  'data-qa': 'confirm-section',
};

ConfirmSection.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  subheader: PropTypes.string,
  text: PropTypes.string,
  subTexts: PropTypes.arrayOf(PropTypes.string),
  confirmMessage: PropTypes.string,
  cancelMessage: PropTypes.string,
  iconComponent: PropTypes.elementType,
  confirmVariation: PropTypes.string,
  leftAlign: PropTypes.bool,
  canError: PropTypes.bool,
  'data-qa': PropTypes.string,
};
