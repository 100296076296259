import { t } from '@lingui/macro';
import { toHumanize } from './utils';

const simpleEmailReg = /^.+@.+\..+$/i;
const specialCharsReg = /[!@#$%^&*(),.?":{}|<>\\/]/;
const phoneNumbersOnlyReg = /^\+?\d*$/;
const dateFormattedReg = /^((?:(\d{4}-\d{2}-\d{2}))T(\d{2}:\d{2}))$/;
const numberReg = /\d+/;
const dateReg = /\d{4}[-./][0,1]\d[-./]\d\d/;
const decimalRegex = (places) => `^\\d*(\\.\\d{1,${places}})?$`;

const ERRORS = {
  required: t`This field cannot be empty.`,
  specialChars: t`This field cannot contain special characters.`,
  numbers: t`This field cannot contain numbers.`,
  email: t`Please use a valid email address.`,
  phone: t`Please enter a valid phone number.`,
  date: t`Please enter a valid date.`,
  dateFormat: t`use yyyy-mm-ddThh:mm:ss format.`,
};

export const required = (value) => (value ? '' : ERRORS.required);

export const hasNoSpecialChars = (value = '') =>
  specialCharsReg.test(value) ? ERRORS.specialChars : '';

export const hasNoNumbers = (value = '') => (numberReg.test(value) ? ERRORS.numbers : '');

export const isEmail = (value = '') => (!value || simpleEmailReg.test(value) ? '' : ERRORS.email);

export const maxLength =
  (maxLen) =>
  (value = '') =>
    value.length > maxLen ? t`Maximum of ${maxLen} characters allowed.` : '';

export const isPhoneNumber = (value = '') => (phoneNumbersOnlyReg.test(value) ? '' : ERRORS.phone);

export const isDate = (value = '') => (!value || dateReg.test(value) ? '' : ERRORS.date);

export const preciseLength =
  (length) =>
  (value = '') =>
    !value || value.length === length ? '' : t`Must be ${length} characters in length.`;

export const preciseLengths =
  (...lengths) =>
  (value = '') =>
    !value || lengths.includes(value.length)
      ? ''
      : t`Must be ${lengths.join(' or ')} characters in length.`;

export const oneOfRequired =
  (fields = [], name = 'errorSection') =>
  (values = {}) => ({
    [name]: fields.reduce((r, c) => !!values[c] || !!r, false)
      ? undefined
      : t`At least one of the fields: ${toHumanize(
          fields.join(' or ')
        ).toLowerCase()} are required.`,
  });

export const isUrl = (value) => {
  if (!value) {
    return '';
  }

  try {
    // eslint-disable-next-line no-new
    new URL(value);
  } catch {
    return 'Invalid URL. It should contain http:// or https:// protocol';
  }

  return '';
};

export const isNumberBetween = (min, max) => (value) =>
  value >= min && value <= max ? '' : t`Must be a number between ${min} and ${max}.`;

export const acceptDecimal = (decimalPlaces) => (value) =>
  new RegExp(decimalRegex(decimalPlaces)).test(value)
    ? ''
    : `This Number must have a maximum of ${decimalPlaces} decimal place${
        decimalPlaces > 1 ? 's' : ''
      }`;

export const isFormattedDate = (date) => (dateFormattedReg.test(date) ? '' : ERRORS.dateFormat);

export const createFieldValidator =
  (...validators) =>
  (...args) =>
    validators.reduce((error, validator) => error || validator(...args), '');
