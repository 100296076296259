import { route } from 'preact-router';

const createPathString = (path, params) => {
  const pathParts = path.split('/');

  const compiledPathPart = pathParts.map((pathPart) => {
    const isDynamic = pathPart.startsWith(':');

    if (isDynamic) {
      const isOptional = pathPart.endsWith('?');
      const dynamicName = pathPart.substring(1, isOptional ? pathPart.length - 1 : pathPart.length);
      const dynamicPath = params[dynamicName];
      if (isOptional && !dynamicPath) {
        return '';
      }

      return dynamicPath;
    }

    return pathPart;
  });

  return compiledPathPart.join('/');
};

const createQueryString = (queryParams) => {
  const queryString = Object.entries(queryParams).reduce((acc, [key, value]) => {
    const param = `${key}=${value}`;
    return acc ? `${acc}&${param}` : param;
  }, '');

  const querySymbol = queryString.length ? '?' : '';

  return querySymbol + queryString;
};

export function createUrl(path, { params = {}, query = {} } = {}) {
  const pathString = createPathString(path, params);
  const queryString = createQueryString(query);

  return pathString + queryString;
}

export function isLocal() {
  return ['localhost', '0.0.0.0', '127.0.0.1'].includes(window.location.hostname);
}

export function navigate(path, { replace = false, params = {}, query = {} } = {}) {
  const isIAM = path.includes('/iam');
  if (isIAM && isLocal()) {
    return;
  }
  const url = createUrl(path, { params, query });

  if (isIAM) {
    window.location.assign(url);
  } else {
    route(url, replace);
  }
}

export function sanitiseId(id) {
  return id
    .replace(/[\s;,?:@&=$%]/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

export function getDifferenceObject(objectA, objectB) {
  return Object.keys(objectA).reduce((accumulator, key) => {
    if (key in objectB && objectB[key] !== objectA[key]) {
      accumulator[key] = objectB[key];
    }
    return accumulator;
  }, {});
}

export const assignValueToKeys = (keys, value = '') =>
  keys.reduce((acc, perm) => ({ ...acc, [perm]: value }), {});

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const delayPromises = (collection, callback, timeout = 50) => {
  return Promise.all(
    collection.map((item) => {
      return delay(timeout).then(() => callback(item));
    })
  );
};

export const padNumber = (number) => number.toString().padStart(2, '0');

export const snakeCaseToCamelCase = (string) => {
  return string.replace(/(_\w)/g, (chars) => chars[1].toUpperCase());
};

function capitalize(string) {
  return `${string[0].toUpperCase()}${string.substring(1).toLowerCase()}`;
}

export const toCamelCase = (string) => {
  if (!string.includes('-') && !string.includes('_')) {
    if (string === string.toUpperCase()) return string.toLowerCase();
    return string;
  }

  const str = string.replace(/([_-]?[A-Z]+)/gi, (captured) => {
    const withoutDash = captured.replace(/[_-]/, '');
    return capitalize(withoutDash);
  });

  return `${str[0].toLowerCase()}${str.substring(1)}`;
};

export const toDashCase = (string) => string.toLowerCase().replace(/_/g, '-');

export const toHumanize = (string) => {
  if (!string.includes('-') && !string.includes('_')) {
    if (string === string.toUpperCase()) return string.toLowerCase();
    return string;
  }

  const stringNoComputerCharacter = string.replace(/([_-]?[A-Z]+)/gi, (captured) => {
    const withoutDash = captured.replace(/[_-]/, ' ');
    return capitalize(withoutDash);
  });

  return `${stringNoComputerCharacter[0].toLowerCase()}${stringNoComputerCharacter.substring(1)}`;
};

export const transformKeys = (object, stringMethod) => {
  return Object.keys(object).reduce(
    (newObj, key) => ({
      ...newObj,
      [stringMethod(key)]: object[key],
    }),
    {}
  );
};

export const createCallback = (callback, ...parentParams) =>
  callback ? (...params) => callback(...parentParams, ...params) : undefined;

export const isDev = () => {
  const { host } = window.location;
  return host.includes('localhost') || host.includes('dev1') || host.includes('stg1');
};
