import { h, Fragment } from 'preact';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './yotiShare.scss';

import { useLayoutEffect, useState, useRef } from 'preact/hooks';
import { Loading } from '@yoti/ui-kit-components-react/loading';

const { CLIENT_SDK_ID, SHARE_CLIENT_URL } = window.ENV;

export const YotiShare = ({ shareUrlProvider, label, alwaysDisplayQrCode }) => {
  const [Share, setShare] = useState(window?.Yoti?.Share);
  const shareEl = useRef();
  const setYotiShare = () => setShare(window?.Yoti?.Share);

  useLayoutEffect(() => {
    if (Share) {
      const { destroy } = Share.init({
        elements: [
          {
            domId: 'yoti-share',
            shareUrlProvider,
            clientSdkId: CLIENT_SDK_ID,
            shareComplete: { closeDelay: 1000 },
            type: 'inline',
            qr: { title: label },
            alwaysDisplayQrCode,
            displayLearnMoreLink: true,
          },
        ],
      });

      return destroy;
    }

    return () => {};
  }, [Share, shareEl, shareUrlProvider, label, alwaysDisplayQrCode]);

  if (Share) {
    return (
      // TODO TW-991 show loading spinner
      <div
        id="yoti-share"
        className={cn('yoti-share', { 'yoti-share--always-qr': alwaysDisplayQrCode })}
        ref={shareEl}
      />
    );
  }

  return (
    <Fragment>
      <script src={SHARE_CLIENT_URL} onload={setYotiShare} />
      <Loading />
    </Fragment>
  );
};

YotiShare.propTypes = {
  label: PropTypes.string,
  alwaysDisplayQrCode: PropTypes.bool,
  shareUrlProvider: PropTypes.func.isRequired,
};

YotiShare.defaultProps = {
  label: '',
  alwaysDisplayQrCode: false,
};
