import { t } from '@lingui/macro';

export const TEST_OPTIONS_ROUTES = {
  SELF_SWAB: 'self-swab',
  SELF_REGISTRATION: 'self-registration',
  HOME_TEST: 'home-test',
  HOME_TEST_UNVERIFIED: 'home-test-unverified',
};

export const TEST_OPTIONS_VALUES = {
  SELF_REGISTRATION: 'SELF_REGISTRATION',
  SELF_SWAB: 'SELF_SWAB',
  HOME_TEST: 'HOME_TEST',
  HOME_TEST_UNVERIFIED: 'HOME_TEST_UNVERIFIED',
};

export const TEST_OPTIONS_LABELS = {
  SELF_REGISTRATION: t`Self-registration before visit`,
  SELF_SWAB: t`Collect sample remotely`,
  HOME_TEST: t`Home testing with verification`,
  HOME_TEST_UNVERIFIED: t`Home testing without verification`,
};

export const TEST_OPTIONS = [
  { label: TEST_OPTIONS_LABELS.SELF_REGISTRATION, value: TEST_OPTIONS_VALUES.SELF_REGISTRATION },
  { label: TEST_OPTIONS_LABELS.SELF_SWAB, value: TEST_OPTIONS_VALUES.SELF_SWAB },
  { label: TEST_OPTIONS_LABELS.HOME_TEST, value: TEST_OPTIONS_VALUES.HOME_TEST },
  {
    label: TEST_OPTIONS_LABELS.HOME_TEST_UNVERIFIED,
    value: TEST_OPTIONS_VALUES.HOME_TEST_UNVERIFIED,
  },
];
