import { h, Fragment } from 'preact';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import PropTypes from 'prop-types';
import { TextField } from '../textField/textField';
import { required } from '../../services/validators';
import { CountryField } from '../countryField/countryField';

export const AddressSection = ({ title, type, canAutoComplete }) => {
  const formattedType = type ? `${type.toLowerCase()}-` : '';

  return (
    <Fragment>
      <h2 className="customer-details-form__section-title">{title ? i18n._(title) : t`Address`}</h2>

      <div className="customer-details-form__field">
        <TextField
          name={`addressLine1${type}`}
          label={t`Address line 1`}
          autoComplete={canAutoComplete ? 'address-line1' : 'chrome-fix-with-unique-name'}
          validators={[required]}
          data-qa={`customer-details-form-${formattedType}address-line-1`}
        />
      </div>

      <div className="customer-details-form__field">
        <TextField
          name={`addressLine2${type}`}
          label={t`Address line 2`}
          autoComplete={canAutoComplete ? 'address-line2' : 'chrome-fix-with-unique-name'}
          data-qa={`customer-details-form-${formattedType}address-line-2`}
        />
      </div>

      <div className="customer-details-form__field">
        <TextField
          name={`addressLine3${type}`}
          label={t`Address line 3`}
          autoComplete={canAutoComplete ? 'address-line3' : 'chrome-fix-with-unique-name'}
          data-qa={`customer-details-form-${formattedType}address-line-3`}
        />
      </div>

      <div className="customer-details-form__field">
        <TextField
          name={`addressLine4${type}`}
          label={t`City`}
          autoComplete={canAutoComplete ? 'address-line4' : 'chrome-fix-with-unique-name'}
          data-qa={`customer-details-form-${formattedType}address-line-4`}
        />
      </div>

      <div className="customer-details-form__field">
        <TextField
          name={`postcode${type}`}
          label={t`ZIP/postal code`}
          autoComplete={canAutoComplete ? 'postal-code' : 'chrome-fix-with-unique-name'}
          validators={[required]}
          data-qa={`customer-details-form-${formattedType}postcode`}
        />
      </div>

      <div className="customer-details-form__field">
        <CountryField
          name={`countryIso${type}`}
          label={t`Country/Region`}
          validators={[required]}
          data-qa={`customer-details-form-${formattedType}country-iso`}
        />
      </div>
    </Fragment>
  );
};

AddressSection.defaultProps = {
  title: undefined,
  type: '',
  canAutoComplete: false,
};

AddressSection.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  canAutoComplete: PropTypes.bool,
};
