import './readOnlyField.scss';
import { h } from 'preact';
import PropTypes from 'prop-types';
import { edit } from '@yoti/ui-kit-components-react/icons-list';
import Icon from '@yoti/ui-kit-components-react/icon';

export const ReadOnlyField = ({ label, children, isEdited, icon }) => (
  <div className="read-only-field">
    <label className="read-only-field__label">
      {icon && <Icon className="read-only-field__label-icon" component={icon} />}
      {label}
    </label>
    <div className="read-only-field__value" data-qa="read-only-field-value">
      {children}
      {isEdited && <Icon component={edit} />}
    </div>
  </div>
);

ReadOnlyField.defaultProps = {
  isEdited: false,
  icon: null,
  children: null,
};

ReadOnlyField.propTypes = {
  isEdited: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};
