import { t } from '@lingui/macro';

export const ERROR_CODES = {
  'B-USED-TOKEN': t`This token has already been used.`,
  'B-EXPIRED-TOKEN': t`This token has expired`,
  'B-INVALID-API-KEY-NAME': t`The API key you have entered is invalid. Please try again.`,
  'B-INVALID-API-KEY-PERMISSIONS': t`The API permission you have entered is invalid. Please try again`,
  'B-INVALID-BATCH-ID': t`The batch number is invalid. Please check the number is correct and try again.`,
  'B-COMPLETE-INVALID': t`Please enter a valid value.`,
  'B-TEST-NOT-INITIATED': t`There are no tests for the sample id provided.`,
  'B-UNSUPPORTED-RESULT': t`The result for this sample is unsupported.`,
  'B-DATE-OF-BIRTH-REQUIRED': t`This sample has no date of birth linked.`,
  'B-CERTIFICATES-DISABLED': t`Certificates for this organisation have been disabled.`,
  'B-INVALID-DATE': t`The date is invalid. Please use DD/MM/YYYY.`,
  'B-INVALID-FILE-SIZE': t`The file is too large. Please try another.`,
  'B-INVALID-IMAGE-ID': t`Image could not be found.`,
  'B-INVALID-IMAGE': t`The file provided is invalid. Please try another.`,
  'B-TEST-RESULT-ISSUED': t`The result for this test was already issued.`,
  'B-NOT-AUTHENTICATED': t`User must be authenticated.`,
  'B-NOT-WHITELISTED': t`You will need to be added to an organisation.
      Please get in touch with your organisation admin if you would like to be added.`,
  'B-INSUFFICIENT-PERMISSIONS': t`Please contact your Administrator if you require access to this area or functionality.`,
  'B-MALFORMED': t`Please enter a valid value.`,
  'B-UNAUTHORIZED': t`You are not authorised.`,
  'B-INVALID-SHORTNAME': t`Organisation short name should have a maximum of 10 characters, all in lower case with no special characters.`,
  'B-SHORT-NAME-TAKEN': t`An organisation already exists with this short name.`,
  'B-NAME-TAKEN': t`An organisation already exists with this name.`,
  'B-INVALID-ADDITIONAL-FIELDS': t`The provided additional fields are not all valid.`,
  'B-UNSUPPORTED-LANGUAGE': t`The language chosen is unsupported.`,
  'B-POOLING-EMAIL-REQUIRED': t`Email is required for pooling, please enable it in the data collection settings page first.`,
  'B-INVALID-MACHINE-MODEL': t`Please enter a valid machine model.`,
  'B-INVALID-CERTIFICATE-OPTIONS': t`Please enter a valid certificate option.`,
  'B-INVALID-DATA-RETENTION': t`Please enter a valid data retention time frame.`,
  'B-INVALID-COUNTRY': t`Please enter a valid country.`,
  'B-MISSING-REGISTERED-ADDRESS': t`Please fill in your organisation registered address on the settings page.`,
  'B-REQUIRED-CONTACT-INFO': t`Please fill your organisation contact details on the settings page.`,
  'B-INVALID-REGISTRATION-NUMBER': t`Please try again.`,
  'B-INVALID-SELF-REGISTRATION-ID': t`Invalid self registration id.`,
  'B-INVALID-SELF-REG-SETTINGS': t`Invalid self registration setting`,
  'B-INVALID-TESTING-SCHEME': t`Please enter a valid testing scheme.`,
  'B-REQUIRED-PRIVACY-POLICY': t`A privacy policy link is required.`,
  'B-INVALID-PREREG-ID': t`Please enter a valid pre registration id.`,
  'B-PRE-REG-DISABLED': t`Pre registration is currently disabled. Please enable and try again.`,
  'B-INVALID-EMAIL': t`Please enter a valid email.`,
  'B-MISSING-CUSTOMER-DATA': t`Some customer data is missing.`,
  'B-SELF-SWAB-DISABLED': t`Self swab is currently disabled. Please enable and try again.`,
  'B-SELF-REG-DISABLED': t`Self registration is currently disabled. Please enable and try again.`,
  'B-INVALID-DATE-OF-BIRTH': t`Please enter a valid date of birth.`,
  'B-INVALID-CQ': t`Invalid Cq. Please enter a number.`,
  'B-EDIT-NOT-SUPPORTED': t`Editing test results is not supported for this test.`,
  'B-BAG-USED': t`The bag is already in use.`,
  'B-INVALID-BAG-ID': t`The bag ID provided is invalid. Please check the number is correct and try again.`,
  'B-EDIT-DISABLED': t`Editing results is currently disabled. Please enable and try again.`,
  'B-INVALID-CHANGED-RESULT': t`Please enter a valid result.`,
  'B-INVALID-LIMIT': t`Please enter a valid range.`,
  'B-INVALID-OFFSET': t`Please enter a valid range.`,
  'B-INVALID-BOOL': t`Please enter a valid value.`,
  'B-POOL-COMPLETE': t`The bag provided has already completed the pool. Please start a new bag.`,
  'B-POOL-CAPACITY': t`The max number of samples for a pool has been reached. Please start a new bag.`,
  'B-INVALID-YOTI-TOKEN': t`There was an issue with the Yoti QR.`,
  'B-MISSING-EMAIL': t`Required email address is missing.`,
  'B-MISSING-DOB': t`Required date of birth is missing.`,
  'B-MISSING-FULL-NAME': t`Required full name is missing.`,
  'B-MISSING-DOCUMENT': t`Required document is missing.`,
  'B-MISSING-PHONE-NUMBER': t`Required phone number is missing.`,
  'B-MISSING-BOOKING-REFERENCE': t`Required booking reference is missing.`,
  'B-INVALID-DOCUMENT': t`The provided document is invalid.`,
  'B-SYMPTOMS-ALREADY-EXIST': t`Symptoms have already been added for this person.`,
  'B-INVALID-SAMPLE-ID': t`The sample id provided is invalid.`,
  'B-MISSING-HOME-ADDRESS': t`Required home address is missing.`,
  'B-INVALID-HOME-ADDRESS': t`Please enter a valid home address.`,
  'B-INVALID-HEALTH-NUMBER': t`Please enter a valid health number.`,
  'B-INVALID-BIRTH-NUMBER': t`Please enter a valid birth number.`,
  'B-INVALID-GIVEN-NAMES': t`Please enter a valid given names.`,
  'B-INVALID-FAMILY-NAME': t`Please enter a valid family name.`,
  'B-INVALID-GENDER': t`Please enter a valid gender.`,
  'B-INVALID-NATIONALITY': t`Please enter a valid nationality.`,
  'B-MISSING-STAYING-ADDRESS': t`Required staying address is missing.`,
  'B-INVALID-STAYING-ADDRESS': t`Please enter a valid staying address.`,
  'B-MISSING-DATE-OF-ARRIVAL': t`Required date of arrival is missing.`,
  'B-INVALID-COUNTRY-FROM': t`Please enter a valid country from.`,
  'B-INVALID-COUNTRIES-VISITED': t`Please enter a valid countries visited`,
  'B-INVALID-FLIGHT-NUMBER': t`Please enter a valid flight number.`,
  'B-INVALID-DEPARTURE-DATE': t`Please enter a valid departure date.`,
  'B-INVALID-ETHNICITY': t`Please enter a valid ethnicity.`,
  'B-INVALID-VACCINATION-STATUS': t`Please enter a valid vaccination status`,
  'B-INVALID-EUDCC-SETTINGS': t`Invalid Client ID and/or Secret. Please check and enter your details again`,
  'B-INVALID-EUDCC-CREDENTIALS': t`Invalid Client ID and/or Secret. Please check and enter your details again`,
  'B-MISSING-FUTURE-DEPARTURE-DATE': t`Required date of departure is missing.`,
  'B-MISSING-UNIQUE-REFERENCE': t`Required unique reference is missing.`,
  'B-SAMPLE-LINKED': t`The sample is already linked.`,
  'B-NAME-REQUIRED': t`This sample has no name linked.`,
  'B-NOT-WEBFORM': t`The selected sample can not be edited.`,
  'B-NOT-ISSUED': t`There was an error issuing the result. Please try again.`,
  'B-INVALID-TEST-ID': t`The test ID provided is invalid. Please check the number is correct and try again.`,
  'B-INVALID-BATCH-TEST': t`The test or batch number provided is invalid. Please check the number is correct and try again.`,
  'B-BAG-ASSIGNED': t`The bag is already assigned in a batch.`,
  'B-RESULT-NOT-HELD': t`This result is not held.`,
  'B-NO-RESULT': t`No new results were found. Please check the file and try again.`,
  'B-BAG-EXPIRED': t`The bag you have scanned was collected more than 7 days ago, and can no longer be added to a test plate.`,
  'B-BAG-EXPIRED-2': t`Customers with expired tests are sent an unclear result and will need to be retested.`,
  'B-EMPTY-BAG': t`The bag is empty. Please make sure someone has been assigned to the bag first.`,
  'B-UNSUPPORTED-POOL': t`The test type selected does not support pooling.`,
  'B-UNSUPPORTED-ISSUE': t`The provided test doesn't support issuing at this point.`,
  'B-INVALID-RESULT': t`The result provided is invalid.`,
  'B-MISSING-CQ-DETAILS': t`The result is missing some cq details.`,
  'B-REVOKE-NOT-POSSIBLE': t`Revoke is not possible.`,
  'B-YOTI-REISSUE': t`The provided test doesn't support reissuing.`,
  'B-BAG-HAS-RESULT': t`This bag already has a result linked to it.`,
  'B-NO-FILE': t`The file is empty. Please check the file and try again.`,
  'B-UNSUPPORTED-TYPE': t`The filetype is invalid. Please use CSV.`,
  'B-UNSUPPORTED-NAME': t`Your filename is not formatted properly. Filename must contain numbers.`,
  'B-BATCH-ALREADY-UPLOADED': t`This batch has already been uploaded.`,
  'B-EXTERNAL-TESTS-DISABLED': t`External results is disabled for your organisation. Please turn on from the settings page.`,
  'B-EMPTY-BATCH': t`The batch has no tests assigned. Please check the file is correct.`,
  'B-INVALID-ORG': t`The organisation is invalid.`,
  'B-INVALID-USER': t`The user ID is invalid.`,
  'B-INVALID-USER-ORG': t`You do not belong to this organisation.`,
  'B-INVALID-PERMISSIONS': t`Some of the permissions you are trying to set do not exist.`,
  'B-ORG-ACCESS': t`This asset belongs to another organisation. Please double check you are logged in to the correct organisation.`,
  'B-UNAUTHORIZED-PERMISSIONS': t`You are not authorised to set these permissions.`,
  'B-MISSING-FIELD': t`One or more required fields is missing.`,
  'B-EXISTING-EMAIL': t`A user with this email already exists.
    If you’re not using this email in your organisation, it could be that this person already has an account with another organisation.
    Please get in contact with this person to resolve the issue.`,
  'B-INVALID-FILE': t`The file is invalid. Please check the file and try again.`,
  'B-FILE-STRUCTURE': t`Your file is not formatted properly. Please repair it and try again.`,
  'B-MISSING-TEST': t`The batch is unexpectedly missing a test.`,
  'B-IMG-SIZE': t`The image you are uploading is too big. Please upload an image with dimensions smaller than 2000px x 2000px`,
  'B-HOME-TEST-UNVERIFIED-DISABLED': t`Unverified home testing is not enabled currently.`,
  'B-HOME-TEST-DISABLED': t`Home testing is not enabled currently.`,
  'B-INVALID-BAG-TOKEN': t`The bag token is invalid.`,
  'B-INVALID-PROOF-IMAGE-ID': t`The image is invalid.`,
  'B-RESULT-ALREADY-CHECKED': t`The result has already been checked.`,
  'B-NOT-SELF-DECLARED': t`The result is not self decalred by the patient.`,
  'B-RESULT-NOT-VERIFIED': t`The result has not been verified.`,
  'B-INVALID-EMAIL-SETTINGS': t`The email settings are invalid. Please check your settings.`,
  'Permission denied': t`Please give access to the camera and refresh.`,
  'F-SERVER-ERROR': t`There was a technical error. Please try again.`,
  'F-SERVICE-UNAVAILABLE': t`This feature is currently unavailable. Please try again later.`,
  'F-MULTIPLE-CHOICES': t`Not selected organisation found. Please select an organisation to continue.`,
  'NO-DATA-FOUND': t`No data could be found. Please try again.`,
  'NO-RESULTS-UPDATED': t`No new results were found. Please check the file and try again.`,
  'REQUEST-ID': (reqId) => {
    const str = t`If you require more assistance quote the request id "${reqId}" to Yoti's customer service team.`;
    return str.replace('{reqId}', reqId);
  },
};
