import './swabResult.scss';
import { h } from 'preact';
import PropTypes from 'prop-types';
import { edit } from '@yoti/ui-kit-components-react/icons-list';
import { Chip, CHIP_VARIATIONS } from '@yoti/ui-kit-components-react/chip';
import * as RESULTS from '../../constant/results';

const TO_VARIATION = {
  [RESULTS.VIRUS_NOT_DETECTED]: CHIP_VARIATIONS.SUCCESS,
  [RESULTS.VIRUS_OR_ANTIGEN_NOT_DETECTED]: CHIP_VARIATIONS.SUCCESS,
  [RESULTS.VIRUS_DETECTED]: CHIP_VARIATIONS.DANGER,
  [RESULTS.VIRUS_OR_ANTIGEN_DETECTED]: CHIP_VARIATIONS.DANGER,
  [RESULTS.INVALID]: CHIP_VARIATIONS.NEUTRAL,
  [RESULTS.IN_PROGRESS]: CHIP_VARIATIONS.INFO,
  [RESULTS.VOID]: CHIP_VARIATIONS.WARNING,
};

export const SwabResult = ({ value, isEdited }) => (
  <div className="swab-result">
    <Chip
      text={RESULTS.TO_LABEL[value]}
      variation={TO_VARIATION[value]}
      icon={isEdited ? edit : undefined}
    />
  </div>
);

SwabResult.defaultProps = {
  value: RESULTS.IN_PROGRESS,
  isEdited: false,
};

SwabResult.propTypes = {
  value: PropTypes.oneOf(Object.values(RESULTS)),
  isEdited: PropTypes.bool,
};
