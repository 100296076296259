import { t } from '@lingui/macro';

export const TEST_SCHEME_STANDARD_VALUE = 'STANDARD';

export const TEST_SCHEME_VALUES = {
  STANDARD: '',
  TEST_TO_RELEASE: 'TEST_TO_RELEASE',
  DAY_2: 'DAY_2',
};

export const TEST_SCHEME_LABELS = {
  STANDARD: t`Standard`,
  TEST_TO_RELEASE: t`Test to release`,
  DAY_2: t`Day 2`,
};

export const TEST_SCHEME_OPTIONS = [
  { label: TEST_SCHEME_LABELS.DAY_2, value: TEST_SCHEME_VALUES.DAY_2 },
  { label: TEST_SCHEME_LABELS.TEST_TO_RELEASE, value: TEST_SCHEME_VALUES.TEST_TO_RELEASE },
  { label: TEST_SCHEME_LABELS.STANDARD, value: TEST_SCHEME_VALUES.STANDARD },
];
