import { BUTTON_VARIATIONS } from '@yoti/ui-kit-components-react';

export const VIRUS_OR_ANTIGEN_NOT_DETECTED = 'VIRUS_OR_ANTIGEN_NOT_DETECTED';
export const VIRUS_OR_ANTIGEN_DETECTED = 'VIRUS_OR_ANTIGEN_DETECTED';
export const INVALID = 'INVALID';
export const VOID = 'VOID';
export const IN_PROGRESS = '';

// deprecated values
export const VIRUS_NOT_DETECTED = 'VIRUS_NOT_DETECTED';
export const VIRUS_DETECTED = 'VIRUS_DETECTED';

export const TO_LABEL = {
  [VIRUS_NOT_DETECTED]: 'Negative',
  [VIRUS_OR_ANTIGEN_NOT_DETECTED]: 'Negative',
  [VIRUS_DETECTED]: 'Positive',
  [VIRUS_OR_ANTIGEN_DETECTED]: 'Positive',
  [INVALID]: 'Unclear',
  [IN_PROGRESS]: 'In progress',
  [VOID]: 'Void',
};
export const isoUK = 'GBR';
export const RESULT_OPTIONS = [
  {
    label: TO_LABEL[VIRUS_OR_ANTIGEN_NOT_DETECTED],
    value: VIRUS_OR_ANTIGEN_NOT_DETECTED,
    variation: BUTTON_VARIATIONS.SUCCESS,
  },
  {
    label: TO_LABEL[VIRUS_OR_ANTIGEN_DETECTED],
    value: VIRUS_OR_ANTIGEN_DETECTED,
    variation: BUTTON_VARIATIONS.DANGER,
  },
  {
    label: TO_LABEL[INVALID],
    value: INVALID,
    variation: BUTTON_VARIATIONS.TERTIARY,
  },
  {
    label: TO_LABEL[VOID],
    value: VOID,
    variation: BUTTON_VARIATIONS.WARNING,
  },
];
