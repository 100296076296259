const EXTRA_DETAILS = [
  'health_number',
  'birth_number',
  'staying_address',
  'date_of_arrival',
  'future_departure_date',
  'country_from',
  'countries_visited',
  'flight_number',
  'departure_date',
  'ethnicity',
  'vaccination_status',
  'testing_scheme',
  'unique_reference',
  'booking_reference',
];

export function getOnlyExtraFields(fields) {
  return Object.entries(fields).reduce(
    (acc, [key, value]) => (EXTRA_DETAILS.includes(key) ? { ...acc, [key]: value } : acc),
    {}
  );
}

export function getOnlyExtraDetails(additionalFields) {
  return additionalFields.filter(({ name }) => EXTRA_DETAILS.includes(name));
}

export function hasExtraDetails(fields, testingSchemes = []) {
  return (
    !!getOnlyExtraDetails(fields).length || (Array.isArray(testingSchemes) && testingSchemes.length)
  );
}

function getDocumentFields(currentFields, shouldBeIncluded, docType) {
  return [
    ...currentFields.filter((field) => field.name !== 'document'),
    ...(shouldBeIncluded ? [{ name: 'document', sources: [docType] }] : []),
  ];
}

function getFields(currentFields, name, shouldBeIncluded) {
  if (shouldBeIncluded) return [...currentFields, { name }];

  return currentFields.filter((field) => field.name !== name);
}

export function getAdditionalFields(currentFields = [], name, ...values) {
  if (name === 'document') {
    return getDocumentFields(currentFields, ...values);
  }
  return getFields(currentFields, name, ...values);
}

export function getAdditionalFieldsInDisplayFormat(results) {
  if (!results) return {};

  return {
    fullName: results.full_name,
    registrationId: results.registration_number,
    email: results.email,
    phone: results.phone,
    dateOfBirth: results?.date_of_birth?.split('T')[0],
    nationality: results?.extra_details?.nationality,
    gender: results?.extra_details?.gender,
    givenNames: results?.extra_details?.given_names,
    familyName: results?.extra_details?.family_name,
    healthNumber: results?.extra_details?.health_number,
    birthNumber: results?.extra_details?.birth_number,
    updated: results?.updated?.split('T')[0],
    overallResult: results?.overall_result,
    resultCreated: results?.result_created?.split('T')[0],
    ...(results.document && {
      documentType: results.document.document_type,
      documentNumber: results?.document.document_number,
      issuingCountry: results.document.issuing_country,
      expirationDate: results.document.expiration_date?.split('T')[0],
    }),
    ...(results.document_details && {
      documentType: results.document_details.document_type,
      documentNumber: results?.document_details.document_number,
      issuingCountry: results.document_details.issuing_country,
      expirationDate: results.document_details.expiration_date?.split('T')[0],
    }),
    ...(results.home_address && {
      addressLine1: results.home_address.address_line_1,
      addressLine2: results.home_address.address_line_2,
      addressLine3: results.home_address.address_line_3,
      addressLine4: results.home_address.address_line_4,
      postcode: results.home_address.postal_code,
      countryIso: results.home_address.country_iso,
    }),

    ...(results?.extra_details?.staying_address && {
      addressLine1Staying: results.extra_details.staying_address.address_line_1,
      addressLine2Staying: results.extra_details.staying_address.address_line_2,
      addressLine3Staying: results.extra_details.staying_address.address_line_3,
      addressLine4Staying: results.extra_details.staying_address.address_line_4,
      postcodeStaying: results.extra_details.staying_address.postal_code,
      countryIsoStaying: results.extra_details.staying_address.country_iso,
    }),
    dateArrival: results?.extra_details?.date_of_arrival?.split('T')[0],
    dateDeparture: results?.extra_details?.future_departure_date?.split('T')[0],
    travellingFrom: results?.extra_details?.country_from,
    travellingJourney: results?.extra_details?.countries_visited,
    transportNumber: results?.extra_details?.flight_number,
    departureDate: results?.extra_details?.departure_date?.split('T')[0],
    ethnicity: results?.extra_details?.ethnicity,
    vaccinationStatus: results?.extra_details?.vaccination_status,
    testingScheme: results?.extra_details?.testing_scheme || results?.testing_scheme,
    uniqueReference: results?.extra_details?.unique_reference,
    bookingReference: results?.extra_details?.booking_reference,
  };
}

export function getAdditionalFieldsInServerFormat({
  fullName,
  email,
  phone,
  dateOfBirth,
  documentType,
  issuingCountry,
  expirationDate,
  documentNumber,
  nationality,
  gender,
  givenNames,
  familyName,
  healthNumber,
  birthNumber,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  postcode,
  countryIso,
  addressLine1Staying,
  addressLine2Staying,
  addressLine3Staying,
  addressLine4Staying,
  postcodeStaying,
  countryIsoStaying,
  dateArrival,
  travellingFrom,
  travellingJourney,
  transportNumber,
  departureDate,
  ethnicity,
  vaccinationStatus,
  testingScheme,
  dateDeparture,
  uniqueReference,
  bookingReference,
} = {}) {
  return {
    ...(fullName && { full_name: fullName }),
    ...(email && { email }),
    ...(phone && { phone }),
    ...(dateOfBirth && { date_of_birth: `${dateOfBirth}T00:00:00.00Z` }),
    ...(nationality && { nationality }),
    ...(gender && { gender }),
    ...(givenNames && { given_names: givenNames }),
    ...(familyName && { family_name: familyName }),
    ...(healthNumber && { health_number: healthNumber }),
    ...(birthNumber && { birth_number: birthNumber }),
    ...(documentType && {
      document: {
        document_type: documentType,
        document_number: documentNumber,
        issuing_country: issuingCountry,
        ...(expirationDate && { expiration_date: `${expirationDate}T00:00:00.00Z` }),
      },
    }),
    ...(addressLine1 && {
      home_address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        address_line_3: addressLine3,
        address_line_4: addressLine4,
        postal_code: postcode,
        country_iso: countryIso,
      },
    }),

    ...(addressLine1Staying && {
      staying_address: {
        address_line_1: addressLine1Staying,
        address_line_2: addressLine2Staying,
        address_line_3: addressLine3Staying,
        address_line_4: addressLine4Staying,
        postal_code: postcodeStaying,
        country_iso: countryIsoStaying,
      },
    }),
    ...(dateArrival && { date_of_arrival: `${dateArrival}T00:00:00.00Z` }),
    ...(dateDeparture && { future_departure_date: `${dateDeparture}T00:00:00.00Z` }),
    ...(travellingFrom && { country_from: travellingFrom }),
    ...(travellingJourney && { countries_visited: travellingJourney }),
    ...(transportNumber && { flight_number: transportNumber }),
    ...(departureDate && { departure_date: `${departureDate}T00:00:00.00Z` }),
    ...(ethnicity && { ethnicity }),
    ...(vaccinationStatus && { vaccination_status: vaccinationStatus }),
    ...(testingScheme && { testing_scheme: testingScheme }),
    ...(uniqueReference && { unique_reference: uniqueReference }),
    ...(bookingReference && { booking_reference: bookingReference }),
  };
}

export function getVisibleFields(additionalFields = [], forceFields = ['email']) {
  // TODO TW-634 BE to align names
  const correctPhoneName = additionalFields.map((field) => ({
    ...field,
    ...(field.name === 'phone_number' && { name: 'phone' }),
  }));
  const formatForced = forceFields.map((field) => ({ name: field }));

  return [...correctPhoneName, ...formatForced];
}

export function hasMissingFields(data, additionalFields = []) {
  const fields = getVisibleFields(additionalFields);
  const serverData = getAdditionalFieldsInServerFormat(data);

  return fields.reduce((isMissing, field) => {
    if (isMissing) return true;
    if (!field.optional) return !serverData[field.name];
    return false;
  }, false);
}

export function hasRequiredFields(fields = [], requiredFields = []) {
  const fieldNames = fields.map(({ name }) => name);
  return requiredFields.every((field) => fieldNames.includes(field));
}

export function groupExtraFieldsInServerFormat(fields) {
  const {
    nationality,
    gender,
    given_names: givenNames,
    family_name: familyName,
    health_number: healthNumber,
    birth_number: birthNumber,
    date_of_arrival: dateOfArrival,
    future_departure_date: futureDepartureDate,
    country_from: countryFrom,
    countries_visited: countriesVisited,
    flight_number: flightNumber,
    departure_date: departureDate,
    ethnicity,
    vaccination_status: vaccinationStatus,
    testing_scheme: testingScheme,
    unique_reference: uniqueReference,
    booking_reference: bookingReference,
    ...rootFields
  } = fields;
  return {
    ...rootFields,
    extra_details: {
      nationality,
      gender,
      given_names: givenNames,
      family_name: familyName,
      health_number: healthNumber,
      birth_number: birthNumber,
      date_of_arrival: dateOfArrival,
      future_departure_date: futureDepartureDate,
      country_from: countryFrom,
      countries_visited: countriesVisited,
      flight_number: flightNumber,
      departure_date: departureDate,
      ethnicity,
      vaccination_status: vaccinationStatus,
      testing_scheme: testingScheme,
      unique_reference: uniqueReference,
      booking_reference: bookingReference,
    },
  };
}

export function getOnlyOrgFields(data, additionalFields = []) {
  const fields = getVisibleFields(additionalFields, ['email', 'testing_scheme']);
  const serverData = getAdditionalFieldsInServerFormat(data);

  const filteredData = fields.reduce(
    (acc, { name }) => ({ ...acc, [name]: serverData[name] || '' }),
    {}
  );
  return getAdditionalFieldsInDisplayFormat(groupExtraFieldsInServerFormat(filteredData));
}
